export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddInternalCodeStoreInput = {
  internalCode: Scalars['String'];
  storeId: Scalars['Int'];
};

export type AddMessageToSuggestedOrderScheduledNotification = {
  message: ScheduledSuggestedOrderNotificationMessageInput;
  scheduledNotificationId: Scalars['Int'];
};

export type AddProductToPriceListInput = {
  priceListId: Scalars['Int'];
  products: Array<InputMaybe<ProductsToAdd>>;
};

export type AddStoreToCommertialRouteInput = {
  id: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type AddStoreToPriceListInput = {
  priceListId: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type AddStoreToPromotionInput = {
  id: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type AddStoreToStockGroupInput = {
  id: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  addressLine: Scalars['String'];
  apartmentNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  addressLine: Scalars['String'];
  apartmentNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type AdminUserCreatedResponse = {
  __typename?: 'AdminUserCreatedResponse';
  id: Scalars['Int'];
  name: Scalars['String'];
  user: ApplicationUser;
};

export type ApplicationUser = {
  __typename?: 'ApplicationUser';
  id: Scalars['Int'];
  username: Scalars['String'];
};

export enum ApplicationUserStatus {
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export type AppliedDiscount = {
  __typename?: 'AppliedDiscount';
  discountTierMax?: Maybe<Scalars['Int']>;
  discountTierMin: Scalars['Int'];
  discountTierPercentage: Scalars['Int'];
  promotionId: Scalars['Int'];
  total: Money;
};

export type AppliedDiscountData = {
  amount: MoneyInput;
  promotionId: Scalars['Int'];
  requiredProducts: Array<ProductInPromotionInput>;
  tier: DiscountTierInput;
};

export enum AuthNextStep {
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
  Verify = 'VERIFY'
}

export type AuthorizedDeviceInput = {
  origin?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type BaseError = {
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type BasicProductInPromotion = {
  __typename?: 'BasicProductInPromotion';
  boxRatio: Scalars['Int'];
  codeDUN?: Maybe<Scalars['String']>;
  codeUPC?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  identificationCode: Scalars['String'];
  image: Scalars['String'];
  /** @deprecated Use boxRatio instead */
  maxDisplayUnits: Scalars['Int'];
  name: Scalars['String'];
};

export enum BonusCrossedLabel {
  MultipleBenefits = 'MULTIPLE_BENEFITS'
}

export type BonusCrossedPillInfo = {
  __typename?: 'BonusCrossedPillInfo';
  label: BonusCrossedLabel;
};

export type BonusIndividualPillInfo = {
  __typename?: 'BonusIndividualPillInfo';
  pay: Scalars['Int'];
  take: Scalars['Int'];
};

export type BonusTier = {
  __typename?: 'BonusTier';
  id: Scalars['Int'];
  quantityGiven: Scalars['Int'];
  quantityRequired: Scalars['Int'];
  rewardedDisplayProduct: DisplayProduct;
  rewardedDisplayProductId: Scalars['Int'];
};

export type BonusTierDetail = {
  __typename?: 'BonusTierDetail';
  id: Scalars['Int'];
  quantityRequired: Scalars['Int'];
  rewardedProductDetails: RewardedProductDetails;
};

export type Brand = {
  __typename?: 'Brand';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  logo: Scalars['String'];
  name: Scalars['String'];
  supplier?: Maybe<Supplier>;
};

export type BrandFacet = {
  name: Scalars['String'];
};

export type CancelOrderInput = {
  id: Scalars['Int'];
  reasonCode: Scalars['String'];
};

export type CancelationReason = {
  __typename?: 'CancelationReason';
  code: Scalars['String'];
  forStore: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  supplierId: Scalars['Int'];
};

export type CategoryFacet = {
  name: Scalars['String'];
};

export type ChangeDisplayProductStatusInput = {
  displayProductId: Scalars['Int'];
  enabled: Scalars['Boolean'];
};

export type ChangeProductStatusInput = {
  enabled: Scalars['Boolean'];
  productId: Scalars['Int'];
};

export type ChangeStoreStatusInput = {
  enabled: Scalars['Boolean'];
  storeId: Scalars['Int'];
};

export enum Channel {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type CheckInput = {
  origin?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CommertialRoute = {
  __typename?: 'CommertialRoute';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  internalCode: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  quantityStores?: Maybe<Scalars['Int']>;
  salesRep?: Maybe<SalesRep>;
};

export type CommertialRouteData = {
  __typename?: 'CommertialRouteData';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  internalCode: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type ConfirmForgotPasswordInput = {
  confirmationCode: Scalars['String'];
  newPassword: Scalars['String'];
  origin?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type ConfirmForgotPasswordResponse = {
  __typename?: 'ConfirmForgotPasswordResponse';
  status: ConfirmForgotPasswordResult;
};

export enum ConfirmForgotPasswordResult {
  InvalidCode = 'INVALID_CODE',
  Success = 'SUCCESS'
}

export type ConfirmOrderInput = {
  deliveryDate?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type ConversationalChannel = {
  __typename?: 'ConversationalChannel';
  botId: Scalars['String'];
  channelId: Scalars['String'];
  id: Scalars['Int'];
  phoneNumber: Scalars['String'];
};

export type ConversationalChannelInput = {
  botId: Scalars['String'];
  channelId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ConvertDisplayProductDefaultInput = {
  displayProductId: Scalars['Int'];
  productId: Scalars['Int'];
};

export type CreateAdminUserInput = {
  name: Scalars['String'];
  username: Scalars['String'];
};

export type CreateBonusTierData = {
  displayProductId: Scalars['Int'];
  productInternalCode: Scalars['String'];
  quantityGiven: Scalars['Int'];
  quantityRequired: Scalars['Int'];
};

export type CreateBrandInput = {
  logo: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCategoryInput = {
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['Int']>;
};

export type CreateCommertialRouteInput = {
  internalCode: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  salesRepId?: InputMaybe<Scalars['Int']>;
};

export type CreateCrossedBonusPromotionInput = {
  bonusTierData: CreateBonusTierData;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
  startDate: Scalars['String'];
  title: Scalars['String'];
};

export type CreateEmailUserStoreInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  storeId: Scalars['Int'];
};

export type CreateEmailUserSupplierInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['Int'];
  userType: UserTypeSupplier;
};

export type CreateIndividualBonusPromotionInput = {
  bonusTierData: CreateBonusTierData;
  endDate: Scalars['String'];
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
  startDate: Scalars['String'];
};

export type CreateLanguageInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateLeadStoreInput = {
  address?: InputMaybe<Scalars['String']>;
  appartmentNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['Int'];
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CreateManufacturerInput = {
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  supportDirectDistribution?: InputMaybe<Scalars['Boolean']>;
};

export type CreateMultipleDiscountPromotionInput = {
  description?: InputMaybe<Scalars['String']>;
  discountTiersData: Array<DiscountTiersData>;
  endDate: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
  startDate: Scalars['String'];
  title: Scalars['String'];
};

export type CreateOrderInput = {
  addressId: Scalars['Int'];
  appliedDiscounts: Array<AppliedDiscountData>;
  paymentDetailsData: Array<PaymentDetailsData>;
  paymentMethod: Scalars['String'];
  /** @deprecated Use total instead */
  price?: InputMaybe<MoneyInput>;
  products: Array<ProductInOrder>;
  rewardedProducts: Array<RewardedProductData>;
  storeId: Scalars['Int'];
  subtotal: MoneyInput;
  total: MoneyInput;
  totalAppliedDiscount: MoneyInput;
};

export type CreatePaymentMethodInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePhoneUserStoreInput = {
  countryCode: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  storeId: Scalars['Int'];
};

export type CreatePriceListInput = {
  internalCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateProductInput = {
  brandId: Scalars['Int'];
  categoryId: Scalars['Int'];
  codeDUN?: InputMaybe<Scalars['String']>;
  codeUPC?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  display: Array<InputMaybe<DisplayProductInput>>;
  identificationCode: Scalars['String'];
  image: Scalars['String'];
  images: Array<ProductImageUpdateInput>;
  name: Scalars['String'];
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type CreateReasonCancelInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type CreateSalesRepInput = {
  commertialRouteInternalCode?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  internalCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateScheduledNotificationTypeInput = {
  channel?: InputMaybe<SupplierScheduledNotificationChannel>;
  code: Scalars['String'];
  name: Scalars['String'];
  supplierId: Scalars['Int'];
  template: Scalars['String'];
};

export type CreateSimpleDiscountPromotionInput = {
  discountTiersData: Array<DiscountTiersData>;
  endDate: Scalars['String'];
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
  startDate: Scalars['String'];
};

export type CreateSiteConfigurationInput = {
  backgroundImage: Scalars['String'];
  conversationalChannel?: InputMaybe<ConversationalChannelInput>;
  favicon: Scalars['String'];
  icon: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  onboardingDescription: Scalars['String'];
  onboardingTitle: Scalars['String'];
  primaryPrincipalColor: Scalars['String'];
  subdomain: Scalars['String'];
  supplierId: Scalars['Int'];
};

export type CreateStockGroupInput = {
  internalCode: Scalars['String'];
  name: Scalars['String'];
};

export type CreateStoreInput = {
  addressFormat?: InputMaybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  addresses: Array<AddressInput>;
  latitude?: InputMaybe<Scalars['String']>;
  legalId: Scalars['String'];
  legalName: Scalars['String'];
  longitude?: InputMaybe<Scalars['String']>;
  ownerIdentifier?: InputMaybe<Scalars['String']>;
  ownerName: Scalars['String'];
  storeSettings?: InputMaybe<CreateStoreSettingsData>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CreateStorePreferencesInput = {
  languageCode?: InputMaybe<Scalars['String']>;
  paymentMethodCode?: InputMaybe<Scalars['String']>;
  storeId: Scalars['Int'];
  timezoneIdentifier?: InputMaybe<Scalars['String']>;
};

export type CreateStoreSettingsData = {
  supportContactEmail?: InputMaybe<Scalars['String']>;
  supportContactPhone?: InputMaybe<Scalars['String']>;
};

export type CreateStoreSettingsInput = {
  cutoffData?: InputMaybe<CutoffData>;
  dropSizeData?: InputMaybe<MoneyData>;
  languagesCodes?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodsCodes?: InputMaybe<Array<Scalars['String']>>;
  storeId: Scalars['Int'];
  supportContactEmail?: InputMaybe<Scalars['String']>;
  supportContactPhone?: InputMaybe<Scalars['String']>;
  timezoneIdentifiers?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateSupplierInput = {
  avatar?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: SupplierType;
};

export type CreateSupplierSettingsInput = {
  currency: Scalars['String'];
  cutoffData?: InputMaybe<CutoffData>;
  dropSizeData?: InputMaybe<MoneyData>;
  languagesCodes: Array<Scalars['String']>;
  paymentMethodsCodes: Array<Scalars['String']>;
  preferenceData: PreferenceData;
  supplierId?: InputMaybe<Scalars['Int']>;
  supportContactEmail?: InputMaybe<Scalars['String']>;
  supportContactPhone?: InputMaybe<Scalars['String']>;
  timezoneIdentifiers: Array<Scalars['String']>;
};

export type CreateTimezoneInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  utcOffset?: InputMaybe<Scalars['Int']>;
};

export type Credit = {
  __typename?: 'Credit';
  defaulter?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  money: Money;
};

export type CronExpressionData = {
  dayOfMonth?: InputMaybe<Scalars['String']>;
  dayOfWeek?: InputMaybe<Scalars['String']>;
  hour: Scalars['String'];
  minute?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['String']>;
};

export type CrossedBonusPromotion = {
  __typename?: 'CrossedBonusPromotion';
  bonusTier: BonusTierDetail;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  pillInfo: BonusCrossedPillInfo;
  products: Array<ProductInPromotion>;
  title: Scalars['String'];
  type: PromotionType;
};

export type CursorBasedInfo = {
  __typename?: 'CursorBasedInfo';
  firstCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  lastCursor: Scalars['String'];
};

export type CursorBasedInput = {
  cursor: Scalars['String'];
};

export type Cutoff = {
  __typename?: 'Cutoff';
  cutOffDay?: Maybe<Scalars['String']>;
  cutOffTime?: Maybe<Scalars['String']>;
  deliveryDay?: Maybe<Scalars['String']>;
  deliveryTime?: Maybe<Scalars['String']>;
  nextCutoffDate: Scalars['String'];
  nextDeliveryDate: Scalars['String'];
};

export type CutoffData = {
  cutOff: CronExpressionData;
  delivery: CronExpressionData;
};

export type DeleteUserStoreInput = {
  storeId: Scalars['Int'];
  username: Scalars['String'];
};

export type DeliverOrderInput = {
  complete: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type DeliveryDetails = {
  __typename?: 'DeliveryDetails';
  channel?: Maybe<Channel>;
  destination: Scalars['String'];
};

export type DiscountPillInfo = {
  __typename?: 'DiscountPillInfo';
  discountAmount: Scalars['Float'];
  pillDiscountType: PillDiscountType;
};

export type DiscountTier = {
  __typename?: 'DiscountTier';
  discountPercentage: Scalars['Float'];
  id: Scalars['Int'];
  max?: Maybe<Scalars['Int']>;
  min: Scalars['Int'];
};

export type DiscountTierInput = {
  discountPercentage: Scalars['Float'];
  id: Scalars['Int'];
  max?: InputMaybe<Scalars['Int']>;
  min: Scalars['Int'];
};

export type DiscountTiersData = {
  discountPercentage: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['Int']>;
  min: Scalars['Int'];
};

export type DisplayProduct = {
  __typename?: 'DisplayProduct';
  codeDUN?: Maybe<Scalars['String']>;
  codeUPC?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  id?: Maybe<Scalars['Int']>;
  internalCode: Scalars['String'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  units: Scalars['Int'];
};

export type DisplayProductInPromotion = {
  __typename?: 'DisplayProductInPromotion';
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  price: Money;
  productId: Scalars['Int'];
  promotions?: Maybe<Array<PromotionDetail>>;
  storeUnavailabiliy: Scalars['Boolean'];
  unitPrice: Money;
  units: Scalars['Int'];
};

export type DisplayProductInPromotionInput = {
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  price: MoneyInput;
  productId: Scalars['Int'];
  storeUnavailabiliy: Scalars['Boolean'];
  unitPrice: MoneyInput;
  units: Scalars['Int'];
};

export type DisplayProductInput = {
  codeDUN?: InputMaybe<Scalars['String']>;
  codeUPC?: InputMaybe<Scalars['String']>;
  default: Scalars['Boolean'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  internalCode?: InputMaybe<Scalars['String']>;
  units: Scalars['Int'];
};

export type DisplayProductResponse = {
  __typename?: 'DisplayProductResponse';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  internalCode?: Maybe<Scalars['String']>;
  price?: Maybe<Money>;
  units: Scalars['Int'];
};

export type DropSize = {
  __typename?: 'DropSize';
  isValid?: Maybe<Scalars['Boolean']>;
  money: Money;
};

export type FacetInput = {
  brand?: InputMaybe<BrandFacet>;
  category?: InputMaybe<CategoryFacet>;
};

export type Facets = {
  __typename?: 'Facets';
  brands?: Maybe<Array<NameFacet>>;
  categories?: Maybe<Array<ImageNameFacet>>;
};

export type FileShareNotificationMessage = {
  __typename?: 'FileShareNotificationMessage';
  createdAt: Scalars['String'];
  file: Scalars['String'];
  id: Scalars['Int'];
  store: Store;
};

export type FileShareNotificationScheduled = {
  __typename?: 'FileShareNotificationScheduled';
  id: Scalars['Int'];
  messages?: Maybe<Array<FileShareNotificationMessage>>;
};

export type FileShareNotificationScheduledResponse = {
  __typename?: 'FileShareNotificationScheduledResponse';
  fileShareNotificationScheduled: FileShareNotificationScheduled;
};

export type Filter = {
  search?: InputMaybe<Scalars['String']>;
};

export type FindAllLeadStoresInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
};

export type FindAllOrdersInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
  status?: InputMaybe<OrderStatus>;
  storeId?: InputMaybe<Scalars['Int']>;
};

export type FindAllPriceListsInput = {
  filter?: InputMaybe<StatusFilter>;
  page?: InputMaybe<PageInput>;
};

export type FindAllStoresInput = {
  filter?: InputMaybe<StatusFilter>;
  page?: InputMaybe<PageInput>;
};

export type FindAllSuppliersInput = {
  page?: InputMaybe<PageInput>;
};

export type FindAllUsersStoreInput = {
  channel: Channel;
  storeId: Scalars['Int'];
};

export type FindBrandsInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
};

export type FindCommertialRoutesInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
};

export type FindProductByIdForStoreInput = {
  productId: Scalars['Int'];
  storeId: Scalars['Int'];
};

export type FindProductsInPriceListInput = {
  filter?: InputMaybe<Filter>;
  included?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<PageInput>;
  priceListId: Scalars['Int'];
};

export type FindProductsInput = {
  filter?: InputMaybe<StatusFilter>;
  page?: InputMaybe<PageInput>;
};

export type FindPromotionByIdInput = {
  id: Scalars['Int'];
  storeId: Scalars['Int'];
};

export type FindPromotionsForStoreByTypeInput = {
  page?: InputMaybe<PageInput>;
  storeId: Scalars['Int'];
};

export type FindPromotionsInput = {
  filter?: InputMaybe<PromotionFilter>;
  page?: InputMaybe<PageInput>;
};

export type FindPromotionsTotalsForStoreInput = {
  storeId: Scalars['Int'];
};

export type FindReasonCancelInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
};

export type FindSalesRepsInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
};

export type FindSiteConfigurationInput = {
  subdomain?: InputMaybe<Scalars['String']>;
};

export type FindStockGroupsInput = {
  filter?: InputMaybe<StockGroupFilter>;
  page?: InputMaybe<PageInput>;
};

export type FindStorePreferencesInput = {
  storeId: Scalars['Int'];
};

export type FindStoreProfileResponse = {
  __typename?: 'FindStoreProfileResponse';
  loggedUser: User;
  profiles: Array<Maybe<Profile>>;
};

export type FindStoreSettingsInput = {
  storeId: Scalars['Int'];
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type FindStoresInPromotionInput = {
  filter?: InputMaybe<Filter>;
  id: Scalars['Int'];
  page?: InputMaybe<PageInput>;
};

export type FindStoresInternalCodesInCommertialRouteInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
  routeId: Scalars['Int'];
};

export type FindStoresInternalCodesInPriceListInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
  priceListId: Scalars['Int'];
};

export type FindStoresInternalCodesInStockGroupInput = {
  filter?: InputMaybe<Filter>;
  page?: InputMaybe<PageInput>;
  stockGroupId: Scalars['Int'];
};

export type FindSupplierProfileResponse = {
  __typename?: 'FindSupplierProfileResponse';
  loggedUser: User;
  supplier: Supplier;
};

export type FindSupplierSettingsInput = {
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type ForgotPasswordInput = {
  origin?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type GetUrlForBrandLogoUploadResponse = {
  __typename?: 'GetUrlForBrandLogoUploadResponse';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type GetUrlForCategoryImageUploadResponse = {
  __typename?: 'GetUrlForCategoryImageUploadResponse';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type GetUrlForManufacturerLogoUploadResponse = {
  __typename?: 'GetUrlForManufacturerLogoUploadResponse';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type GetUrlForProductImageUploadResponse = {
  __typename?: 'GetUrlForProductImageUploadResponse';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type GetUrlForPromotionImageUploadResponse = {
  __typename?: 'GetUrlForPromotionImageUploadResponse';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type ImageNameFacet = {
  __typename?: 'ImageNameFacet';
  count: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type IndexProductsForSupplierInput = {
  indexer: IndexerName;
  supplierId: Scalars['Int'];
};

export enum IndexerName {
  Algolia = 'ALGOLIA',
  Typesense = 'TYPESENSE'
}

export type IndividualBonusPromotion = {
  __typename?: 'IndividualBonusPromotion';
  bonusTier: BonusTierDetail;
  id: Scalars['Int'];
  pillInfo: BonusIndividualPillInfo;
  products: Array<ProductInPromotion>;
  type: PromotionType;
};

export type InitAuthenticationInput = {
  origin?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type InitAuthenticationResponse = SetPasswordRequired | UserAuthenticatedResponse;

export type Language = {
  __typename?: 'Language';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type LeadStore = {
  __typename?: 'LeadStore';
  address?: Maybe<Scalars['String']>;
  appartmentNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  ownerName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ListingCategory = {
  __typename?: 'ListingCategory';
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ListingDisplay = {
  __typename?: 'ListingDisplay';
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  price: Money;
  promotions?: Maybe<Array<Maybe<PromotionDetail>>>;
  storeUnavailabiliy: Scalars['Boolean'];
  unitPrice: Money;
  units: Scalars['Int'];
};

export type ListingProduct = {
  __typename?: 'ListingProduct';
  brand: Scalars['String'];
  category: ListingCategory;
  description: Scalars['String'];
  displays: Array<ListingDisplay>;
  id: Scalars['Int'];
  image: Scalars['String'];
  name: Scalars['String'];
  notification: Scalars['Boolean'];
};

export type ListingProductDetail = {
  __typename?: 'ListingProductDetail';
  brand: Scalars['String'];
  category: ListingCategory;
  description?: Maybe<Scalars['String']>;
  displays: Array<ListingDisplay>;
  id: Scalars['Int'];
  image: Scalars['String'];
  images: Array<Maybe<ProductImage>>;
  name: Scalars['String'];
  notification: Scalars['Boolean'];
};

export enum LoginMethodType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type Manufacturer = {
  __typename?: 'Manufacturer';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  supportDirectDistribution: Scalars['Boolean'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Int'];
  currency: Scalars['String'];
  formatted?: Maybe<MoneyFormat>;
  scale: Scalars['Int'];
};


export type MoneyFormattedArgs = {
  format?: InputMaybe<Scalars['String']>;
};

export type MoneyData = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type MoneyFormat = {
  __typename?: 'MoneyFormat';
  text: Scalars['String'];
  value: Scalars['Float'];
};

export type MoneyInput = {
  amount: Scalars['Int'];
  currency: Scalars['String'];
  scale: Scalars['Int'];
};

export type MultipleDiscountPromotion = {
  __typename?: 'MultipleDiscountPromotion';
  description?: Maybe<Scalars['String']>;
  discountTiers: Array<DiscountTier>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  pillInfo: DiscountPillInfo;
  products: Array<ProductInPromotion>;
  title: Scalars['String'];
  type: PromotionType;
};

export type Mutation = {
  __typename?: 'Mutation';
  addInternalCodeStore?: Maybe<MutationStoreResponse>;
  addMessageToSuggestedOrderScheduledNotification: SuggestedOrderNotificationMessageResponse;
  addProductToPriceList: MutationPriceListResponse;
  addStoreToCommertialRoute: MutationCommertialRouteResponse;
  addStoreToPriceList: MutationPriceListResponse;
  addStoreToPromotion: MutationPromotionResponse;
  addStoreToStockGroup: MutationStockGroupResponse;
  authorizedDeviceAuth: UserAuthenticatedResponse;
  cancelOrder: MutationOrderResponse;
  cancelOrderByStore: MutationOrderResponse;
  changeDisplayProductStatus: MutationDisplayProductResponse;
  changeProductStatus: MutationProductResponse;
  changeSiteConfigurationStatus: SiteConfigurationResponse;
  changeStoreStatus: MutationStoreResponse;
  confirmForgotPassword: ConfirmForgotPasswordResponse;
  confirmOrder: MutationOrderResponse;
  convertDisplayProductDefault: MutationDisplayProductCovertResponse;
  createAdminUser: AdminUserCreatedResponse;
  createBrand: MutationBrandResponse;
  createCategory: MutationCategoryResponse;
  createCommertialRoute: MutationCommertialRouteResponse;
  createCrossedBonusPromotion: MutationPromotionResponse;
  createEmailUserStore: MutationUserStoreResponse;
  createEmailUserSupplier: MutationUserSupplierResponse;
  createIndividualBonusPromotion: MutationPromotionResponse;
  createLanguage: MutationLanguageResponse;
  createLeadStore: MutationLeadStoreResponse;
  createManufacturer: MutationManufacturerResponse;
  createMultipleDiscountPromotion: MutationPromotionResponse;
  createOrder: MutationOrderResponse;
  createPaymentMethod: MutationPaymentMethodResponse;
  createPhoneUserStore: MutationUserStoreResponse;
  createPriceList: MutationPriceListResponse;
  createProduct: MutationProductResponse;
  createReasonCancel: MutationReasonCancelResponse;
  createSalesRep: MutationSalesRepResponse;
  createScheduledNotification: ScheduledNotificationResponse;
  createScheduledNotificationType: ScheduledNotificationTypeResponse;
  createSimpleDiscountPromotion: MutationPromotionResponse;
  createSiteConfiguration: SiteConfigurationResponse;
  createStockGroup: MutationStockGroupResponse;
  createStore: MutationStoreResponse;
  createStorePreferences: MutationPreferenceResponse;
  createStoreSettings: MutationSettingResponse;
  createSupplier: MutationSupplierResponse;
  createSupplierSettings: MutationSettingResponse;
  createTimezone: MutationTimezoneResponse;
  deleteCategory: MutationCategoryResponse;
  deleteStockGroup: MutationStockGroupResponse;
  deleteStore: MutationStoreResponse;
  deleteUserStore: MutationUserStoreResponse;
  deliverOrder: MutationOrderResponse;
  forgotPassword: VerificationCodeSentResponse;
  indexProductsForSupplier: Scalars['Boolean'];
  /** Initiate authentication flow, also verify account */
  initAuthentication: InitAuthenticationResponse;
  notifyProductAvailability: Scalars['Boolean'];
  /** Refresh token */
  refreshToken: TokenRefreshedResponse;
  removeProductFromPriceList: MutationPriceListResponse;
  removeStoreFromCommertialRoute: MutationCommertialRouteResponse;
  removeStoreFromPriceList: MutationPriceListResponse;
  removeStoreFromPromotion: MutationPromotionResponse;
  removeStoreFromStockGroup: MutationStockGroupResponse;
  /** Resend verification code */
  resendVerificationCode: VerificationCodeSentResponse;
  /** Resend verification code users STORE */
  resendVerificationCodeByUsersStore: VerificationCodeSentResponse;
  runScheduledNotification: ScheduledNotificationResponse;
  scheduleFileShareNotification: FileShareNotificationScheduledResponse;
  scheduleSuggestedOrderNotification: SuggestedOrderNotificationScheduledResponse;
  setBrandStatus: MutationBrandResponse;
  setCommertialRouteStatus: MutationCommertialRouteResponse;
  setManufacturerDirectDistributionStatus: MutationManufacturerResponse;
  setManufacturerStatus: MutationManufacturerResponse;
  setPassword: UserAuthenticatedResponse;
  setPriceListStatus: MutationPriceListResponse;
  setPromotionStatus: MutationPromotionResponse;
  setReasonCancelStatus: MutationReasonCancelResponse;
  setSalesRepStatus: MutationSalesRepResponse;
  setStockGroupStatus: MutationStockGroupResponse;
  setSupplierStatus: MutationSupplierResponse;
  simulateOrder: SimulateOrderResponse;
  updateBrand: MutationBrandResponse;
  updateCategory: MutationCategoryResponse;
  updateCommertialRoute: MutationCommertialRouteResponse;
  updateCrossedBonusPromotion: MutationPromotionResponse;
  updateIndividualBonusPromotion: MutationPromotionResponse;
  updateLanguage: MutationLanguageResponse;
  updateManufacturer: MutationManufacturerResponse;
  updateMultipleDiscountPromotion: MutationPromotionResponse;
  updateOrderDeliveryDate: MutationOrderResponse;
  updatePaymentMethod: MutationPaymentMethodResponse;
  updatePriceList: MutationPriceListResponse;
  updateProduct: MutationProductResponse;
  updateProductOnPriceList: MutationPriceListResponse;
  updateReasonCancel: MutationReasonCancelResponse;
  updateSalesRep: MutationSalesRepResponse;
  updateSimpleDiscountPromotion: MutationPromotionResponse;
  updateSiteConfiguration: SiteConfigurationResponse;
  updateStockGroup: MutationStockGroupResponse;
  updateStore: MutationStoreResponse;
  updateStorePreferences: MutationPreferenceResponse;
  updateStoreSettings: MutationSettingResponse;
  updateSupplier: MutationSupplierResponse;
  updateSupplierSettings: MutationSettingResponse;
  updateTimezone: MutationTimezoneResponse;
  updateUserStore: MutationUserStoreResponse;
  upsertStoreSettings?: Maybe<MutationSettingResponse>;
};


export type MutationAddInternalCodeStoreArgs = {
  addInternalCodeStoreInput: AddInternalCodeStoreInput;
};


export type MutationAddMessageToSuggestedOrderScheduledNotificationArgs = {
  input: AddMessageToSuggestedOrderScheduledNotification;
};


export type MutationAddProductToPriceListArgs = {
  addProductToPriceListInput: AddProductToPriceListInput;
};


export type MutationAddStoreToCommertialRouteArgs = {
  addStoreToCommertialRouteInput: AddStoreToCommertialRouteInput;
};


export type MutationAddStoreToPriceListArgs = {
  addStoreToPriceListInput: AddStoreToPriceListInput;
};


export type MutationAddStoreToPromotionArgs = {
  addStoreToPromotionInput: AddStoreToPromotionInput;
};


export type MutationAddStoreToStockGroupArgs = {
  addStoreToStockGroupInput: AddStoreToStockGroupInput;
};


export type MutationAuthorizedDeviceAuthArgs = {
  input: AuthorizedDeviceInput;
};


export type MutationCancelOrderArgs = {
  cancelOrderInput: CancelOrderInput;
};


export type MutationCancelOrderByStoreArgs = {
  cancelOrderInput: CancelOrderInput;
};


export type MutationChangeDisplayProductStatusArgs = {
  changeDisplayProductStatusInput: ChangeDisplayProductStatusInput;
};


export type MutationChangeProductStatusArgs = {
  changeProductStatusInput: ChangeProductStatusInput;
};


export type MutationChangeSiteConfigurationStatusArgs = {
  input: SiteConfigurationUpdateStatusInput;
};


export type MutationChangeStoreStatusArgs = {
  changeStoreStatusInput: ChangeStoreStatusInput;
};


export type MutationConfirmForgotPasswordArgs = {
  input: ConfirmForgotPasswordInput;
};


export type MutationConfirmOrderArgs = {
  confirmOrderInput: ConfirmOrderInput;
};


export type MutationConvertDisplayProductDefaultArgs = {
  convertDisplayProductDefaultInput: ConvertDisplayProductDefaultInput;
};


export type MutationCreateAdminUserArgs = {
  input: CreateAdminUserInput;
};


export type MutationCreateBrandArgs = {
  createBrandInput: CreateBrandInput;
};


export type MutationCreateCategoryArgs = {
  createCategoryInput: CreateCategoryInput;
};


export type MutationCreateCommertialRouteArgs = {
  createCommertialRouteInput: CreateCommertialRouteInput;
};


export type MutationCreateCrossedBonusPromotionArgs = {
  createCrossedBonusPromotionInput: CreateCrossedBonusPromotionInput;
};


export type MutationCreateEmailUserStoreArgs = {
  createEmailUserStoreInput: CreateEmailUserStoreInput;
};


export type MutationCreateEmailUserSupplierArgs = {
  createEmailUserSupplierInput: CreateEmailUserSupplierInput;
};


export type MutationCreateIndividualBonusPromotionArgs = {
  createIndividualBonusPromotionInput: CreateIndividualBonusPromotionInput;
};


export type MutationCreateLanguageArgs = {
  input: CreateLanguageInput;
};


export type MutationCreateLeadStoreArgs = {
  createLeadStoreInput: CreateLeadStoreInput;
};


export type MutationCreateManufacturerArgs = {
  createManufacturerInput: CreateManufacturerInput;
};


export type MutationCreateMultipleDiscountPromotionArgs = {
  createMultipleDiscountPromotionInput: CreateMultipleDiscountPromotionInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePhoneUserStoreArgs = {
  createPhoneUserStoreInput: CreatePhoneUserStoreInput;
};


export type MutationCreatePriceListArgs = {
  createPriceListInput: CreatePriceListInput;
};


export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};


export type MutationCreateReasonCancelArgs = {
  createReasonCancelInput: CreateReasonCancelInput;
};


export type MutationCreateSalesRepArgs = {
  createSalesRepInput: CreateSalesRepInput;
};


export type MutationCreateScheduledNotificationArgs = {
  input: ScheduledNotificationInput;
};


export type MutationCreateScheduledNotificationTypeArgs = {
  input: CreateScheduledNotificationTypeInput;
};


export type MutationCreateSimpleDiscountPromotionArgs = {
  createSimpleDiscountPromotionInput: CreateSimpleDiscountPromotionInput;
};


export type MutationCreateSiteConfigurationArgs = {
  input: CreateSiteConfigurationInput;
};


export type MutationCreateStockGroupArgs = {
  createStockGroupInput: CreateStockGroupInput;
};


export type MutationCreateStoreArgs = {
  createStoreInput: CreateStoreInput;
};


export type MutationCreateStorePreferencesArgs = {
  createStorePreferencesInput: CreateStorePreferencesInput;
};


export type MutationCreateStoreSettingsArgs = {
  createStoreSettingsInput: CreateStoreSettingsInput;
};


export type MutationCreateSupplierArgs = {
  createSupplierInput: CreateSupplierInput;
};


export type MutationCreateSupplierSettingsArgs = {
  createSupplierSettingsInput: CreateSupplierSettingsInput;
};


export type MutationCreateTimezoneArgs = {
  input: CreateTimezoneInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStockGroupArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStoreArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserStoreArgs = {
  deleteUserStoreInput: DeleteUserStoreInput;
};


export type MutationDeliverOrderArgs = {
  deliverOrderInput: DeliverOrderInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationIndexProductsForSupplierArgs = {
  indexProductsForSupplierInput: IndexProductsForSupplierInput;
};


export type MutationInitAuthenticationArgs = {
  input: InitAuthenticationInput;
};


export type MutationNotifyProductAvailabilityArgs = {
  notifyProductAvailabilityInput: NotifyProductAvailabilityInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationRemoveProductFromPriceListArgs = {
  removeProductFromPriceListInput?: InputMaybe<RemoveProductFromPriceListInput>;
};


export type MutationRemoveStoreFromCommertialRouteArgs = {
  removeStoreFromCommertialRouteInput: RemoveStoreFromCommertialRouteInput;
};


export type MutationRemoveStoreFromPriceListArgs = {
  removeStoreFromPriceListInput: RemoveStoreFromPriceListInput;
};


export type MutationRemoveStoreFromPromotionArgs = {
  removeStoreFromPromotionInput: RemoveStoreFromPromotionInput;
};


export type MutationRemoveStoreFromStockGroupArgs = {
  removeStoreFromStockGroupInput: RemoveStoreFromStockGroupInput;
};


export type MutationResendVerificationCodeArgs = {
  input: ResendVerificationCodeInput;
};


export type MutationResendVerificationCodeByUsersStoreArgs = {
  input: ResendVerificationCodeByUsersStoreInput;
};


export type MutationRunScheduledNotificationArgs = {
  input: RunScheduledNotificationInput;
};


export type MutationScheduleFileShareNotificationArgs = {
  input: ScheduleFileShareNotificationInput;
};


export type MutationScheduleSuggestedOrderNotificationArgs = {
  input: ScheduleSuggestedOrderNotificationInput;
};


export type MutationSetBrandStatusArgs = {
  setBrandStatusInput: SetBrandStatusInput;
};


export type MutationSetCommertialRouteStatusArgs = {
  setCommertialRouteStatusInput: SetCommertialRouteStatusInput;
};


export type MutationSetManufacturerDirectDistributionStatusArgs = {
  setManufacturerDirectDistributionStatusInput: SetManufacturerDirectDistributionStatusInput;
};


export type MutationSetManufacturerStatusArgs = {
  setManufacturerStatusInput: SetManufacturerStatusInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};


export type MutationSetPriceListStatusArgs = {
  setPriceListStatusInput: SetPriceListStatusInput;
};


export type MutationSetPromotionStatusArgs = {
  setPromotionStatusInput: SetPromotionStatusInput;
};


export type MutationSetReasonCancelStatusArgs = {
  setReasonCancelStatusInput: SetReasonCancelStatusInput;
};


export type MutationSetSalesRepStatusArgs = {
  setSalesRepStatusInput: SetSalesRepStatusInput;
};


export type MutationSetStockGroupStatusArgs = {
  setStockGroupStatusInput: SetStockGroupStatusInput;
};


export type MutationSetSupplierStatusArgs = {
  setSupplierStatusInput: SetSupplierStatusInput;
};


export type MutationSimulateOrderArgs = {
  simulateOrderInput: SimulateOrderInput;
};


export type MutationUpdateBrandArgs = {
  updateBrandInput: UpdateBrandInput;
};


export type MutationUpdateCategoryArgs = {
  updateCategoryInput: UpdateCategoryInput;
};


export type MutationUpdateCommertialRouteArgs = {
  updateCommertialRouteInput: UpdateCommertialRouteInput;
};


export type MutationUpdateCrossedBonusPromotionArgs = {
  updateCrossedBonusPromotionInput: UpdateCrossedBonusPromotionInput;
};


export type MutationUpdateIndividualBonusPromotionArgs = {
  updateIndividualBonusPromotionInput: UpdateIndividualBonusPromotionInput;
};


export type MutationUpdateLanguageArgs = {
  input: UpdateLanguageInput;
};


export type MutationUpdateManufacturerArgs = {
  updateManufacturerInput: UpdateManufacturerInput;
};


export type MutationUpdateMultipleDiscountPromotionArgs = {
  updateMultipleDiscountPromotionInput: UpdateMultipleDiscountPromotionInput;
};


export type MutationUpdateOrderDeliveryDateArgs = {
  updateOrderDeliveryDateInput: UpdateOrderDeliveryDateInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdatePriceListArgs = {
  updatePriceListInput: UpdatePriceListInput;
};


export type MutationUpdateProductArgs = {
  updateProductInput: UpdateProductInput;
};


export type MutationUpdateProductOnPriceListArgs = {
  updateProductOnPriceListInput: UpdateProductOnPriceListInput;
};


export type MutationUpdateReasonCancelArgs = {
  updateReasonCancelInput: UpdateReasonCancelInput;
};


export type MutationUpdateSalesRepArgs = {
  updateSalesRepInput: UpdateSalesRepInput;
};


export type MutationUpdateSimpleDiscountPromotionArgs = {
  updateSimpleDiscountPromotionInput: UpdateSimpleDiscountPromotionInput;
};


export type MutationUpdateSiteConfigurationArgs = {
  input: UpdateSiteConfigurationInput;
};


export type MutationUpdateStockGroupArgs = {
  updateStockGroupInput: UpdateStockGroupInput;
};


export type MutationUpdateStoreArgs = {
  updateStoreInput: UpdateStoreInput;
};


export type MutationUpdateStorePreferencesArgs = {
  updateStorePreferencesInput: UpdateStorePreferencesInput;
};


export type MutationUpdateStoreSettingsArgs = {
  updateStoreSettingsInput: UpdateStoreSettingsInput;
};


export type MutationUpdateSupplierArgs = {
  updateSupplierInput: UpdateSupplierInput;
};


export type MutationUpdateSupplierSettingsArgs = {
  updateSupplierSettingsInput: UpdateSupplierSettingsInput;
};


export type MutationUpdateTimezoneArgs = {
  input: UpdateTimezoneInput;
};


export type MutationUpdateUserStoreArgs = {
  updateUserStoreInput: UpdateUserStoreInput;
};


export type MutationUpsertStoreSettingsArgs = {
  input: UpsertStoreSettingsInput;
};

export type MutationBrandResponse = {
  __typename?: 'MutationBrandResponse';
  brand?: Maybe<Brand>;
};

export type MutationCategoryResponse = {
  __typename?: 'MutationCategoryResponse';
  category?: Maybe<Category>;
};

export type MutationCommertialRouteResponse = {
  __typename?: 'MutationCommertialRouteResponse';
  commertialRoute: CommertialRoute;
};

export type MutationDisplayProductCovertResponse = {
  __typename?: 'MutationDisplayProductCovertResponse';
  displayProduct?: Maybe<Array<Maybe<DisplayProduct>>>;
};

export type MutationDisplayProductResponse = {
  __typename?: 'MutationDisplayProductResponse';
  displayProduct?: Maybe<DisplayProduct>;
};

export type MutationLanguageResponse = {
  __typename?: 'MutationLanguageResponse';
  language: Language;
};

export type MutationLeadStoreResponse = {
  __typename?: 'MutationLeadStoreResponse';
  leadStore?: Maybe<LeadStore>;
};

export type MutationManufacturerResponse = {
  __typename?: 'MutationManufacturerResponse';
  manufacturer?: Maybe<Manufacturer>;
};

export type MutationOrderResponse = {
  __typename?: 'MutationOrderResponse';
  order: Order;
};

export type MutationPaymentMethodResponse = {
  __typename?: 'MutationPaymentMethodResponse';
  paymentMethod: PaymentMethod;
};

export type MutationPreferenceResponse = {
  __typename?: 'MutationPreferenceResponse';
  preference: Preference;
};

export type MutationPriceListResponse = {
  __typename?: 'MutationPriceListResponse';
  priceList: PriceList;
};

export type MutationProductResponse = {
  __typename?: 'MutationProductResponse';
  product?: Maybe<Product>;
};

export type MutationPromotionResponse = {
  __typename?: 'MutationPromotionResponse';
  promotion: Promotion;
};

export type MutationReasonCancelResponse = {
  __typename?: 'MutationReasonCancelResponse';
  reasonCancel?: Maybe<OrderCancelationReason>;
};

export type MutationSalesRepResponse = {
  __typename?: 'MutationSalesRepResponse';
  salesRep: SalesRep;
};

export type MutationSettingResponse = {
  __typename?: 'MutationSettingResponse';
  setting: Setting;
};

export type MutationStockGroupResponse = {
  __typename?: 'MutationStockGroupResponse';
  stockGroup?: Maybe<StockGroup>;
};

export type MutationStoreResponse = {
  __typename?: 'MutationStoreResponse';
  store?: Maybe<Store>;
};

export type MutationSupplierResponse = {
  __typename?: 'MutationSupplierResponse';
  supplier?: Maybe<Supplier>;
};

export type MutationTimezoneResponse = {
  __typename?: 'MutationTimezoneResponse';
  timezone: Timezone;
};

export type MutationUserStoreResponse = {
  __typename?: 'MutationUserStoreResponse';
  storeUserCreatedResponse?: Maybe<StoreUserCreatedResponse>;
};

export type MutationUserSupplierResponse = {
  __typename?: 'MutationUserSupplierResponse';
  supplierUserCreatedResponse?: Maybe<SupplierUserCreatedResponse>;
};

export type NameFacet = {
  __typename?: 'NameFacet';
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type NotifyProductAvailabilityInput = {
  productId: Scalars['Int'];
};

export type Order = {
  __typename?: 'Order';
  appliedDiscounts?: Maybe<Array<AppliedDiscount>>;
  cancelationReason?: Maybe<CancelationReason>;
  cancelationReasonId?: Maybe<Scalars['Int']>;
  creationDate: Scalars['String'];
  deliveryDate?: Maybe<Scalars['String']>;
  history?: Maybe<Array<OrderHistory>>;
  id: Scalars['Int'];
  orderPaymentMethodId?: Maybe<Scalars['Int']>;
  paymentDetails?: Maybe<Array<Maybe<PaymentDetail>>>;
  paymentMethod?: Maybe<Scalars['String']>;
  /** @deprecated Use total instead */
  price: Money;
  priceList?: Maybe<PriceList>;
  priceListId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<OrderItem>>;
  productsQuantity?: Maybe<Scalars['Int']>;
  rewardedProducts?: Maybe<Array<RewardedProduct>>;
  status: Scalars['String'];
  storeAddress: Scalars['String'];
  storeInternalCode?: Maybe<Scalars['String']>;
  storeLegalId: Scalars['String'];
  storeLegalName?: Maybe<Scalars['String']>;
  storeOwnerName: Scalars['String'];
  subtotal: Money;
  total: Money;
  totalAppliedDiscount?: Maybe<Money>;
};

export type OrderByParameters = {
  criteria: Scalars['String'];
  order: OrderType;
};

export type OrderCancelationReason = {
  __typename?: 'OrderCancelationReason';
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  supplierId: Scalars['Int'];
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  status: OrderStatus;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  brand?: Maybe<Brand>;
  id: Scalars['Int'];
  price: Money;
  productImage: Scalars['String'];
  productInternalCode?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  productUnits: Scalars['Int'];
  promotionBenefit: PromotionBenefit;
  quantity: Scalars['Int'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  Pending = 'PENDING'
}

export enum OrderType {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageBasedInfo = {
  __typename?: 'PageBasedInfo';
  count: Scalars['Int'];
};

export type PageBasedInput = {
  page: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  cursorBased?: Maybe<CursorBasedInfo>;
  pageBased?: Maybe<PageBasedInfo>;
};

export type PageInput = {
  cursorBased?: InputMaybe<CursorBasedInput>;
  orderBy?: InputMaybe<OrderByParameters>;
  pageBased?: InputMaybe<PageBasedInput>;
  take: Scalars['Int'];
};

export type PagedBrand = {
  __typename?: 'PagedBrand';
  brands: Array<Maybe<Brand>>;
  pagination: PageInfo;
};

export type PagedCommertialRoute = {
  __typename?: 'PagedCommertialRoute';
  commertialRoutes: Array<CommertialRoute>;
  pagination: PageInfo;
};

export type PagedCrossedBonusPromotion = {
  __typename?: 'PagedCrossedBonusPromotion';
  pagination: PageInfo;
  promotions: Array<CrossedBonusPromotion>;
};

export type PagedIndividualBonusPromotion = {
  __typename?: 'PagedIndividualBonusPromotion';
  pagination: PageInfo;
  promotions: Array<IndividualBonusPromotion>;
};

export type PagedLeadStore = {
  __typename?: 'PagedLeadStore';
  leadStores: Array<Maybe<LeadStore>>;
  pagination: PageInfo;
};

export type PagedMultipleDiscountPromotion = {
  __typename?: 'PagedMultipleDiscountPromotion';
  pagination: PageInfo;
  promotions: Array<MultipleDiscountPromotion>;
};

export type PagedOrder = {
  __typename?: 'PagedOrder';
  orders: Array<Maybe<Order>>;
  pagination: PageInfo;
};

export type PagedPriceList = {
  __typename?: 'PagedPriceList';
  pagination: PageInfo;
  priceLists: Array<Maybe<PriceList>>;
};

export type PagedProduct = {
  __typename?: 'PagedProduct';
  pagination: PageInfo;
  products: Array<Maybe<Product>>;
};

export type PagedProductOnPriceList = {
  __typename?: 'PagedProductOnPriceList';
  pagination: PageInfo;
  products: Array<Maybe<PriceListProductResponse>>;
};

export type PagedPromotion = {
  __typename?: 'PagedPromotion';
  pagination: PageInfo;
  promotions: Array<Promotion>;
};

export type PagedReasonCancel = {
  __typename?: 'PagedReasonCancel';
  pagination: PageInfo;
  reasonsCancel: Array<Maybe<OrderCancelationReason>>;
};

export type PagedSalesRep = {
  __typename?: 'PagedSalesRep';
  pagination: PageInfo;
  salesReps: Array<SalesRep>;
};

export type PagedSimpleDiscountPromotion = {
  __typename?: 'PagedSimpleDiscountPromotion';
  pagination: PageInfo;
  promotions: Array<SimpleDiscountPromotion>;
};

export type PagedStockGroup = {
  __typename?: 'PagedStockGroup';
  pagination: PageInfo;
  stockGroups: Array<Maybe<StockGroup>>;
};

export type PagedStore = {
  __typename?: 'PagedStore';
  pagination: PageInfo;
  stores: Array<Maybe<Store>>;
};

export type PagedStoreInternalCodes = {
  __typename?: 'PagedStoreInternalCodes';
  pagination: PageInfo;
  storeInternalCodes: Array<Maybe<Scalars['String']>>;
};

export type PagedSupplier = {
  __typename?: 'PagedSupplier';
  pagination: PageInfo;
  suppliers: Array<Maybe<Supplier>>;
};

export type PaymentDetail = {
  __typename?: 'PaymentDetail';
  id: Scalars['Int'];
  money: Money;
  paymentMethodCode: Scalars['String'];
  paymentMethodId: Scalars['Int'];
  paymentMethodName: Scalars['String'];
};

export type PaymentDetailsData = {
  amount: MoneyInput;
  paymentMethodCode: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum PillDiscountType {
  MultipleTier = 'MULTIPLE_TIER',
  SingleTier = 'SINGLE_TIER'
}

export enum Platform {
  Admin = 'ADMIN',
  Backoffice = 'BACKOFFICE',
  Chatbot = 'CHATBOT',
  Pwa = 'PWA',
  Sfa = 'SFA'
}

export type Preference = {
  __typename?: 'Preference';
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Language>;
  paymentMethod?: Maybe<PaymentMethod>;
  timezone?: Maybe<Timezone>;
};

export type PreferenceData = {
  languageCode?: InputMaybe<Scalars['String']>;
  paymentMethodCode?: InputMaybe<Scalars['String']>;
  timezoneIdentifier?: InputMaybe<Scalars['String']>;
};

export type PriceList = {
  __typename?: 'PriceList';
  createdAt: Scalars['Int'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  internalCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  supplierId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PriceListProductResponse = {
  __typename?: 'PriceListProductResponse';
  displayProducts: Array<Maybe<DisplayProductResponse>>;
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  identificationCode: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  brand?: Maybe<Brand>;
  category?: Maybe<Category>;
  codeDUN?: Maybe<Scalars['String']>;
  codeUPC?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  displayProducts?: Maybe<Array<Maybe<DisplayProduct>>>;
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  identificationCode: Scalars['String'];
  image: Scalars['String'];
  images: Array<ProductImage>;
  name: Scalars['String'];
  supplier?: Maybe<Supplier>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  image: Scalars['String'];
};

export type ProductImageUpdateInput = {
  id?: InputMaybe<Scalars['Int']>;
  image: Scalars['String'];
};

export type ProductInOrder = {
  displayProductId: Scalars['Int'];
  price: MoneyInput;
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  subtotal: MoneyInput;
};

export type ProductInPromotion = {
  __typename?: 'ProductInPromotion';
  boxRatio: Scalars['Int'];
  brand: Scalars['String'];
  description: Scalars['String'];
  displays: Array<DisplayProductInPromotion>;
  id: Scalars['Int'];
  image: Scalars['String'];
  images?: Maybe<Array<ProductImage>>;
  /** @deprecated Use boxRatio instead */
  maxDisplayUnits: Scalars['Int'];
  name: Scalars['String'];
  notification: Scalars['Boolean'];
};

export type ProductInPromotionInput = {
  boxRatio: Scalars['Int'];
  brand: Scalars['String'];
  description: Scalars['String'];
  displays: Array<DisplayProductInPromotionInput>;
  id: Scalars['Int'];
  image: Scalars['String'];
  maxDisplayUnits: Scalars['Int'];
  name: Scalars['String'];
};

export type ProductsData = {
  boxRatio: Scalars['Int'];
  identificationCode: Scalars['String'];
};

export type ProductsToAdd = {
  price: Scalars['Float'];
  productId: Scalars['Int'];
  units: Scalars['Int'];
};

export type ProductsToUpdate = {
  displayProductId: Scalars['Int'];
  price: Scalars['Float'];
};

export type Profile = {
  __typename?: 'Profile';
  setting: Setting;
  store: Store;
};

export type Promotion = {
  __typename?: 'Promotion';
  bonusTier?: Maybe<BonusTier>;
  description?: Maybe<Scalars['String']>;
  discountTiers?: Maybe<Array<DiscountTier>>;
  enabled: Scalars['Boolean'];
  endDate: Scalars['String'];
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  internalCode: Scalars['String'];
  name: Scalars['String'];
  products?: Maybe<Array<BasicProductInPromotion>>;
  startDate: Scalars['String'];
  stores?: Maybe<Array<Store>>;
  supplierId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: PromotionType;
};

export enum PromotionBenefit {
  Bonus = 'BONUS',
  Discount = 'DISCOUNT',
  Multiple = 'MULTIPLE',
  None = 'NONE'
}

export type PromotionDetail = CrossedBonusPromotion | IndividualBonusPromotion | MultipleDiscountPromotion | SimpleDiscountPromotion;

export type PromotionFilter = {
  search?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PromotionTypeFilter>;
};

export type PromotionTotals = {
  __typename?: 'PromotionTotals';
  count: Scalars['Int'];
  type: Scalars['String'];
};

export enum PromotionType {
  BonusCrossed = 'BONUS_CROSSED',
  BonusIndividual = 'BONUS_INDIVIDUAL',
  DiscountMultiple = 'DISCOUNT_MULTIPLE',
  DiscountSimple = 'DISCOUNT_SIMPLE'
}

export enum PromotionTypeFilter {
  Bonus = 'BONUS',
  Discount = 'DISCOUNT'
}

export type Query = {
  __typename?: 'Query';
  check: UserCheckedResponse;
  findAllBrands: Array<Maybe<Brand>>;
  findAllLanguages: Array<Maybe<Language>>;
  findAllLeadStores: PagedLeadStore;
  findAllManufacturers: Array<Maybe<Manufacturer>>;
  findAllOrders: PagedOrder;
  findAllPaymentMethods: Array<Maybe<PaymentMethod>>;
  findAllReasonsCancel: Array<Maybe<OrderCancelationReason>>;
  findAllSalesReps: Array<Maybe<SalesRep>>;
  findAllStores: PagedStore;
  findAllStoresForSalesRep: PagedStore;
  findAllSupplierOnlyCancelReasons: Array<OrderCancelationReason>;
  findAllSupplierStores: PagedStore;
  findAllSuppliers: PagedSupplier;
  findAllTimezones: Array<Maybe<Timezone>>;
  findAllUsersStore: Array<Maybe<User>>;
  findBrands: PagedBrand;
  findByInternalCode?: Maybe<Product>;
  findByProductId?: Maybe<Product>;
  findCancelationReasons: Array<CancelationReason>;
  findCartProductsToRepeatOrder: RepeatOrderResponse;
  findChildCategories: Array<Category>;
  findCommertialRouteById?: Maybe<CommertialRoute>;
  findCommertialRoutes: PagedCommertialRoute;
  findCrossedBonusPromotionsForStore: PagedCrossedBonusPromotion;
  findDisplayProductByCode?: Maybe<Array<Maybe<DisplayProduct>>>;
  findDisplayProductByProductId?: Maybe<Array<Maybe<DisplayProduct>>>;
  findIndividualBonusPromotionsForStore: PagedIndividualBonusPromotion;
  findManyProductsByIdentificationCode?: Maybe<Array<Maybe<Product>>>;
  findMultipleDiscountPromotionsForStore: PagedMultipleDiscountPromotion;
  findMySiteConfiguration?: Maybe<SiteConfigurationResponse>;
  findOneBrand?: Maybe<Brand>;
  findOneDisplayProduct?: Maybe<DisplayProduct>;
  findOneManufacturer?: Maybe<Manufacturer>;
  findOneStore?: Maybe<Store>;
  findOneSupplierById?: Maybe<Supplier>;
  findOneSupplierStore?: Maybe<Store>;
  findOrderById: Order;
  findParentCategoriesFromSupplier: Array<Category>;
  findPriceListByStoreId?: Maybe<PriceList>;
  findPriceLists: PagedPriceList;
  findProductByIdForStore: ListingProductDetail;
  findProducts: PagedProduct;
  findProductsInPriceList: PagedProductOnPriceList;
  findPromotionById?: Maybe<Promotion>;
  findPromotionByIdForStore: PromotionDetail;
  findPromotions: PagedPromotion;
  findPromotionsTotalsForStore: Array<Maybe<PromotionTotals>>;
  findReasonsCancel: PagedReasonCancel;
  findSalesRepById?: Maybe<SalesRep>;
  findSalesReps: PagedSalesRep;
  findSimpleDiscountPromotionsForStore: PagedSimpleDiscountPromotion;
  findStockGroups: PagedStockGroup;
  findStorePreferences: Preference;
  findStoreProfile: FindStoreProfileResponse;
  findStoreSettings: Setting;
  findStoresInPromotion: PagedStore;
  findStoresInternalCodesInCommertialRoute: PagedStoreInternalCodes;
  findStoresInternalCodesInPriceList: PagedStoreInternalCodes;
  findStoresInternalCodesInStockGroup: PagedStoreInternalCodes;
  findSupplierProfile: FindSupplierProfileResponse;
  findSupplierSettings: Setting;
  getUrlForBrandLogoUpload?: Maybe<GetUrlForBrandLogoUploadResponse>;
  getUrlForCategoryImageUpload?: Maybe<GetUrlForCategoryImageUploadResponse>;
  getUrlForManufacturerLogoUpload?: Maybe<GetUrlForManufacturerLogoUploadResponse>;
  getUrlForProductImageUpload?: Maybe<GetUrlForProductImageUploadResponse>;
  getUrlForPromotionImageUpload?: Maybe<GetUrlForPromotionImageUploadResponse>;
  getUrlsForProductImageUpload?: Maybe<Array<Maybe<GetUrlForProductImageUploadResponse>>>;
  search: SearchResponse;
  void: Scalars['Int'];
};


export type QueryCheckArgs = {
  input: CheckInput;
};


export type QueryFindAllLeadStoresArgs = {
  findAllLeadStoresInput?: InputMaybe<FindAllLeadStoresInput>;
};


export type QueryFindAllOrdersArgs = {
  findAllOrdersInput: FindAllOrdersInput;
};


export type QueryFindAllStoresArgs = {
  findAllStoresInput: FindAllStoresInput;
};


export type QueryFindAllStoresForSalesRepArgs = {
  findAllStoresInput: FindAllStoresInput;
};


export type QueryFindAllSuppliersArgs = {
  findAllSuppliersInput?: InputMaybe<FindAllSuppliersInput>;
};


export type QueryFindAllUsersStoreArgs = {
  findAllUsersStoreInput: FindAllUsersStoreInput;
};


export type QueryFindBrandsArgs = {
  findBrandsInput?: InputMaybe<FindBrandsInput>;
};


export type QueryFindByInternalCodeArgs = {
  internalCode: Scalars['String'];
};


export type QueryFindByProductIdArgs = {
  id: Scalars['Int'];
};


export type QueryFindCartProductsToRepeatOrderArgs = {
  orderId: Scalars['Int'];
  storeId: Scalars['Int'];
};


export type QueryFindChildCategoriesArgs = {
  parentId: Scalars['Int'];
};


export type QueryFindCommertialRouteByIdArgs = {
  id: Scalars['Int'];
};


export type QueryFindCommertialRoutesArgs = {
  findCommertialRoutesInput: FindCommertialRoutesInput;
};


export type QueryFindCrossedBonusPromotionsForStoreArgs = {
  input: FindPromotionsForStoreByTypeInput;
};


export type QueryFindDisplayProductByCodeArgs = {
  code: Scalars['String'];
};


export type QueryFindDisplayProductByProductIdArgs = {
  productId: Scalars['Int'];
};


export type QueryFindIndividualBonusPromotionsForStoreArgs = {
  input: FindPromotionsForStoreByTypeInput;
};


export type QueryFindManyProductsByIdentificationCodeArgs = {
  identificationCodes: Array<Scalars['String']>;
};


export type QueryFindMultipleDiscountPromotionsForStoreArgs = {
  input: FindPromotionsForStoreByTypeInput;
};


export type QueryFindMySiteConfigurationArgs = {
  input?: InputMaybe<FindSiteConfigurationInput>;
};


export type QueryFindOneBrandArgs = {
  id: Scalars['Int'];
};


export type QueryFindOneDisplayProductArgs = {
  id: Scalars['Int'];
};


export type QueryFindOneManufacturerArgs = {
  id: Scalars['Int'];
};


export type QueryFindOneStoreArgs = {
  id: Scalars['Int'];
};


export type QueryFindOneSupplierByIdArgs = {
  id: Scalars['Int'];
};


export type QueryFindOneSupplierStoreArgs = {
  id: Scalars['Int'];
};


export type QueryFindOrderByIdArgs = {
  id: Scalars['Int'];
};


export type QueryFindPriceListByStoreIdArgs = {
  id: Scalars['Int'];
};


export type QueryFindPriceListsArgs = {
  findAllPriceListsInput?: InputMaybe<FindAllPriceListsInput>;
};


export type QueryFindProductByIdForStoreArgs = {
  input: FindProductByIdForStoreInput;
};


export type QueryFindProductsArgs = {
  findProductsInput?: InputMaybe<FindProductsInput>;
};


export type QueryFindProductsInPriceListArgs = {
  findProductsInPriceListInput: FindProductsInPriceListInput;
};


export type QueryFindPromotionByIdArgs = {
  id: Scalars['Int'];
};


export type QueryFindPromotionByIdForStoreArgs = {
  input: FindPromotionByIdInput;
};


export type QueryFindPromotionsArgs = {
  findPromotionsInput?: InputMaybe<FindPromotionsInput>;
};


export type QueryFindPromotionsTotalsForStoreArgs = {
  input: FindPromotionsTotalsForStoreInput;
};


export type QueryFindReasonsCancelArgs = {
  findReasonCancelInput?: InputMaybe<FindReasonCancelInput>;
};


export type QueryFindSalesRepByIdArgs = {
  id: Scalars['Int'];
};


export type QueryFindSalesRepsArgs = {
  findSalesRepsInput: FindSalesRepsInput;
};


export type QueryFindSimpleDiscountPromotionsForStoreArgs = {
  input: FindPromotionsForStoreByTypeInput;
};


export type QueryFindStockGroupsArgs = {
  findStockGroupsInput?: InputMaybe<FindStockGroupsInput>;
};


export type QueryFindStorePreferencesArgs = {
  input: FindStorePreferencesInput;
};


export type QueryFindStoreSettingsArgs = {
  input: FindStoreSettingsInput;
};


export type QueryFindStoresInPromotionArgs = {
  findStoresInPromotionInput: FindStoresInPromotionInput;
};


export type QueryFindStoresInternalCodesInCommertialRouteArgs = {
  findStoresInternalCodesInCommertialRouteInput: FindStoresInternalCodesInCommertialRouteInput;
};


export type QueryFindStoresInternalCodesInPriceListArgs = {
  findStoresInternalCodesInPriceListInput: FindStoresInternalCodesInPriceListInput;
};


export type QueryFindStoresInternalCodesInStockGroupArgs = {
  findStoresInternalCodesInStockGroupInput: FindStoresInternalCodesInStockGroupInput;
};


export type QueryFindSupplierSettingsArgs = {
  input?: InputMaybe<FindSupplierSettingsInput>;
};


export type QueryGetUrlForBrandLogoUploadArgs = {
  name: Scalars['String'];
};


export type QueryGetUrlForCategoryImageUploadArgs = {
  name: Scalars['String'];
};


export type QueryGetUrlForManufacturerLogoUploadArgs = {
  name: Scalars['String'];
};


export type QueryGetUrlForProductImageUploadArgs = {
  name: Scalars['String'];
};


export type QueryGetUrlForPromotionImageUploadArgs = {
  name: Scalars['String'];
};


export type QueryGetUrlsForProductImageUploadArgs = {
  names: Array<Scalars['String']>;
};


export type QuerySearchArgs = {
  input?: InputMaybe<SearchInput>;
};


export type QueryVoidArgs = {
  id: Scalars['Int'];
};

export type RefreshTokenInput = {
  origin?: InputMaybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  userSub: Scalars['String'];
  username: Scalars['String'];
};

export type RemoveProductFromPriceListInput = {
  priceListId: Scalars['Int'];
  productDisplayIds: Array<InputMaybe<Scalars['Int']>>;
};

export type RemoveStoreFromCommertialRouteInput = {
  id: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type RemoveStoreFromPriceListInput = {
  priceListId: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type RemoveStoreFromPromotionInput = {
  id: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type RemoveStoreFromStockGroupInput = {
  id: Scalars['Int'];
  storeInternalCode: Scalars['String'];
};

export type RepeatOrderItem = {
  __typename?: 'RepeatOrderItem';
  product: ListingProduct;
  quantity: Scalars['Int'];
  status: RepeatOrderItemStatus;
};

export enum RepeatOrderItemStatus {
  HasChanges = 'HAS_CHANGES',
  Ok = 'OK',
  Unavailable = 'UNAVAILABLE'
}

export type RepeatOrderResponse = {
  __typename?: 'RepeatOrderResponse';
  items: Array<RepeatOrderItem>;
  status: RepeatOrderStatus;
};

export enum RepeatOrderStatus {
  HasChanges = 'HAS_CHANGES',
  Ok = 'OK',
  Unavailable = 'UNAVAILABLE'
}

export type ResendVerificationCodeByUsersStoreInput = {
  username: Scalars['String'];
};

export type ResendVerificationCodeInput = {
  origin?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type RewardedProduct = {
  __typename?: 'RewardedProduct';
  displayProductId: Scalars['Int'];
  displayProductUnits: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  productDescription: Scalars['String'];
  productImage: Scalars['String'];
  productName: Scalars['String'];
  promotionId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type RewardedProductData = {
  accumulatedProductsRewarded: Scalars['Int'];
  accumulatedRewards: Scalars['Int'];
  promotionId: Scalars['Int'];
  requiredProducts: Array<ProductInPromotionInput>;
  rewardedProductDetails: RewardedProductDetailsInput;
};

export type RewardedProductDetails = {
  __typename?: 'RewardedProductDetails';
  displayProductId: Scalars['Int'];
  displayProductUnits: Scalars['Int'];
  productDescription: Scalars['String'];
  productId: Scalars['Int'];
  productImage: Scalars['String'];
  productName: Scalars['String'];
  rewardedQuantity: Scalars['Int'];
};

export type RewardedProductDetailsInput = {
  displayProductId: Scalars['Int'];
  displayProductUnits: Scalars['Int'];
  productDescription: Scalars['String'];
  productId: Scalars['Int'];
  productImage: Scalars['String'];
  productName: Scalars['String'];
  rewardedQuantity: Scalars['Int'];
};

export type RunScheduledNotificationInput = {
  scheduledNotificationId: Scalars['Int'];
};

export type SalesRep = {
  __typename?: 'SalesRep';
  commertialRoute?: Maybe<CommertialRouteData>;
  countryCode: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  internalCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  username: Scalars['String'];
};

export type ScheduleFileShareNotificationInput = {
  scheduledNotificationId: Scalars['Int'];
  withMessages: Array<ScheduleFileShareNotificationMessageInput>;
};

export type ScheduleFileShareNotificationMessageInput = {
  file: Scalars['String'];
  storeCode: Scalars['String'];
};

export type ScheduleSuggestedOrderNotificationInput = {
  scheduledAtTimestamp?: InputMaybe<Scalars['Int']>;
  scheduledNotificationId: Scalars['Int'];
  withMessages?: InputMaybe<Array<ScheduledSuggestedOrderNotificationMessageInput>>;
};

export type ScheduledNotification = {
  __typename?: 'ScheduledNotification';
  channel: SupplierScheduledNotificationChannel;
  id: Scalars['Int'];
  notificationType: ScheduledNotificationType;
  schedule: Scalars['String'];
};

export type ScheduledNotificationInput = {
  channel: SupplierScheduledNotificationChannel;
  notificationType: Scalars['Int'];
  schedule: Scalars['String'];
};

export type ScheduledNotificationResponse = {
  __typename?: 'ScheduledNotificationResponse';
  scheduledNotification: ScheduledNotification;
};

export type ScheduledNotificationType = {
  __typename?: 'ScheduledNotificationType';
  code: Scalars['String'];
  id: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  template?: Maybe<Scalars['String']>;
};

export type ScheduledNotificationTypeResponse = {
  __typename?: 'ScheduledNotificationTypeResponse';
  scheduledNotificationType?: Maybe<ScheduledNotificationType>;
};

export type ScheduledSuggestedOrderNotification = {
  __typename?: 'ScheduledSuggestedOrderNotification';
  id: Scalars['Int'];
  messages?: Maybe<Array<ScheduledSuggestedOrderNotificationMessage>>;
  scheduledAt: Scalars['String'];
};

export type ScheduledSuggestedOrderNotificationMessage = {
  __typename?: 'ScheduledSuggestedOrderNotificationMessage';
  channel: SupplierScheduledNotificationChannel;
  createdAt: Scalars['String'];
  displayProduct: DisplayProduct;
  id: Scalars['Int'];
  quantity: Scalars['Int'];
  store: Store;
  units: Scalars['Int'];
};

export type ScheduledSuggestedOrderNotificationMessageInput = {
  channel?: InputMaybe<SupplierScheduledNotificationChannel>;
  productCode: Scalars['String'];
  quantity: Scalars['Int'];
  storeCode: Scalars['String'];
  units: Scalars['Int'];
};

export type SearchInput = {
  facet?: InputMaybe<FacetInput>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  storeId: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  count: Scalars['Int'];
  facets: Facets;
  products: Array<ListingProduct>;
};

export type SetBrandStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetCommertialRouteStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetManufacturerDirectDistributionStatusInput = {
  id: Scalars['Int'];
  supportDirectDistribution: Scalars['Boolean'];
};

export type SetManufacturerStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetPasswordInput = {
  newpassword: Scalars['String'];
  origin?: InputMaybe<Scalars['String']>;
  session: Scalars['String'];
  username: Scalars['String'];
};

export type SetPasswordRequired = {
  __typename?: 'SetPasswordRequired';
  session: Scalars['String'];
};

export type SetPriceListStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetPromotionStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetReasonCancelStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetSalesRepStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetStockGroupStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type SetSupplierStatusInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type Setting = {
  __typename?: 'Setting';
  credit?: Maybe<Credit>;
  currency?: Maybe<Scalars['String']>;
  cutOff?: Maybe<Cutoff>;
  dropSize?: Maybe<DropSize>;
  languages?: Maybe<Array<Language>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  preference?: Maybe<Preference>;
  storeSettingId?: Maybe<Scalars['Int']>;
  supplierSettingId?: Maybe<Scalars['Int']>;
  supportContactEmail?: Maybe<Scalars['String']>;
  supportContactPhone?: Maybe<Scalars['String']>;
  timezones?: Maybe<Array<Timezone>>;
};

export type SimpleDiscountPromotion = {
  __typename?: 'SimpleDiscountPromotion';
  discountTiers: Array<DiscountTier>;
  id: Scalars['Int'];
  pillInfo: DiscountPillInfo;
  products: Array<ProductInPromotion>;
  type: PromotionType;
};

export type SimulateOrderInput = {
  appliedDiscounts: Array<AppliedDiscountData>;
  products: Array<ProductInOrder>;
  rewardedProducts: Array<RewardedProductData>;
  store: Scalars['Int'];
  subtotal: MoneyInput;
  total: MoneyInput;
  totalAppliedDiscount: MoneyInput;
};

export type SimulateOrderResponse = {
  __typename?: 'SimulateOrderResponse';
  deliveryDate?: Maybe<Scalars['String']>;
  price: Money;
};

export type SiteConfiguration = {
  __typename?: 'SiteConfiguration';
  backgroundImage: Scalars['String'];
  conversationalChannel?: Maybe<ConversationalChannel>;
  favicon: Scalars['String'];
  icon: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  logo: Scalars['String'];
  name: Scalars['String'];
  onboardingDescription: Scalars['String'];
  onboardingTitle: Scalars['String'];
  primaryPrincipalColor: Scalars['String'];
  subdomain: Scalars['String'];
  supplierId: Scalars['Int'];
};

export type SiteConfigurationResponse = {
  __typename?: 'SiteConfigurationResponse';
  siteConfiguration?: Maybe<SiteConfiguration>;
};

export type SiteConfigurationUpdateStatusInput = {
  status: Scalars['Boolean'];
  subdomain: Scalars['String'];
};

export type StatusFilter = {
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusTypeFilter>;
};

export enum StatusTypeFilter {
  All = 'ALL',
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type StockGroup = {
  __typename?: 'StockGroup';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  internalCode: Scalars['String'];
  name: Scalars['String'];
  products?: Maybe<Array<Maybe<Product>>>;
  stores?: Maybe<Array<Maybe<Store>>>;
};

export type StockGroupFilter = {
  search?: InputMaybe<Scalars['String']>;
};

export type Store = {
  __typename?: 'Store';
  addressFormat?: Maybe<Scalars['String']>;
  /** @deprecated Use address.addressLine instead */
  addressLine1: Scalars['String'];
  /** @deprecated Use address.apartmentNumber instead */
  addressLine2?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Address>>;
  deleted: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  internalCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  legalId: Scalars['String'];
  legalName: Scalars['String'];
  longitude?: Maybe<Scalars['String']>;
  ownerIdentifier?: Maybe<Scalars['String']>;
  ownerName: Scalars['String'];
  storeSettings?: Maybe<StoreSettings>;
  supplierId: Scalars['Int'];
  users?: Maybe<Array<Maybe<User>>>;
  /** @deprecated Use address.zipCode instead */
  zipCode?: Maybe<Scalars['String']>;
};

export type StoreSettings = {
  __typename?: 'StoreSettings';
  id?: Maybe<Scalars['Int']>;
  supportContactEmail?: Maybe<Scalars['String']>;
  supportContactPhone?: Maybe<Scalars['String']>;
};

export type StoreUserCreatedResponse = {
  __typename?: 'StoreUserCreatedResponse';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status: UserStatusType;
  username: Scalars['String'];
};

export type SuggestedOrderNotificationMessageResponse = {
  __typename?: 'SuggestedOrderNotificationMessageResponse';
  suggestedOrderNotificationMessage: ScheduledSuggestedOrderNotificationMessage;
};

export type SuggestedOrderNotificationScheduled = {
  __typename?: 'SuggestedOrderNotificationScheduled';
  id: Scalars['Int'];
  messages?: Maybe<Array<ScheduledSuggestedOrderNotificationMessage>>;
  scheduledAt: Scalars['String'];
};

export type SuggestedOrderNotificationScheduledResponse = {
  __typename?: 'SuggestedOrderNotificationScheduledResponse';
  suggestedOrderNotificationScheduled: SuggestedOrderNotificationScheduled;
};

export type Supplier = {
  __typename?: 'Supplier';
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  type: SupplierType;
};

export enum SupplierScheduledNotificationChannel {
  ConversationalCommerce = 'CONVERSATIONAL_COMMERCE'
}

export enum SupplierType {
  Cpg = 'CPG',
  Distributor = 'DISTRIBUTOR',
  Wholesaler = 'WHOLESALER'
}

export type SupplierUserCreatedResponse = {
  __typename?: 'SupplierUserCreatedResponse';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status: UserStatusType;
  userType?: Maybe<UserTypeSupplier>;
  username: Scalars['String'];
};

export type Timezone = {
  __typename?: 'Timezone';
  abbreviation?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identifier: Scalars['String'];
  utcOffset?: Maybe<Scalars['Int']>;
};

export type TokenRefreshedResponse = {
  __typename?: 'TokenRefreshedResponse';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  idToken: Scalars['String'];
};

export type UpdateBonusTierData = {
  displayProductId: Scalars['Int'];
  id: Scalars['Int'];
  productInternalCode: Scalars['String'];
  quantityGiven: Scalars['Int'];
  quantityRequired: Scalars['Int'];
};

export type UpdateBrandInput = {
  id: Scalars['Int'];
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCategoryInput = {
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCommertialRouteInput = {
  id: Scalars['Int'];
  internalCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  salesRepId?: InputMaybe<Scalars['Int']>;
};

export type UpdateConversationalChannelInput = {
  botId: Scalars['String'];
  channelId: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  phoneNumber: Scalars['String'];
};

export type UpdateCrossedBonusPromotionInput = {
  bonusTierData: UpdateBonusTierData;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['String']>;
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
  title: Scalars['String'];
};

export type UpdateIndividualBonusPromotionInput = {
  bonusTierData: UpdateBonusTierData;
  id: Scalars['Int'];
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
};

export type UpdateLanguageInput = {
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateManufacturerInput = {
  id: Scalars['Int'];
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMultipleDiscountPromotionInput = {
  description?: InputMaybe<Scalars['String']>;
  discountTiersData: Array<DiscountTiersData>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['String']>;
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
  title: Scalars['String'];
};

export type UpdateOrderDeliveryDateInput = {
  deliveryDate: Scalars['String'];
  id: Scalars['Int'];
};

export type UpdatePaymentMethodInput = {
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdatePriceListInput = {
  id: Scalars['Int'];
  internalCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInput = {
  brandId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  codeDUN?: InputMaybe<Scalars['String']>;
  codeUPC?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  display: Array<InputMaybe<DisplayProductInput>>;
  id: Scalars['Int'];
  identificationCode?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<ProductImageUpdateInput>>;
  name?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type UpdateProductOnPriceListInput = {
  priceListId: Scalars['Int'];
  products: Array<InputMaybe<ProductsToUpdate>>;
};

export type UpdateReasonCancelInput = {
  code: Scalars['String'];
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type UpdateSalesRepInput = {
  commertialRouteInternalCode?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  internalCode?: InputMaybe<Scalars['String']>;
};

export type UpdateSimpleDiscountPromotionInput = {
  discountTiersData: Array<DiscountTiersData>;
  id: Scalars['Int'];
  internalCode: Scalars['String'];
  name: Scalars['String'];
  productsData: Array<ProductsData>;
};

export type UpdateSiteConfigurationInput = {
  backgroundImage: Scalars['String'];
  conversationalChannel?: InputMaybe<UpdateConversationalChannelInput>;
  favicon: Scalars['String'];
  icon: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  onboardingDescription: Scalars['String'];
  onboardingTitle: Scalars['String'];
  primaryPrincipalColor: Scalars['String'];
  subdomain: Scalars['String'];
};

export type UpdateStockGroupInput = {
  id: Scalars['Int'];
  internalCode: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateStoreInput = {
  addressFormat?: InputMaybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  addresses: Array<AddressInput>;
  id: Scalars['Int'];
  latitude?: InputMaybe<Scalars['String']>;
  legalId: Scalars['String'];
  legalName: Scalars['String'];
  longitude?: InputMaybe<Scalars['String']>;
  ownerIdentifier?: InputMaybe<Scalars['String']>;
  ownerName: Scalars['String'];
  storeSettings?: InputMaybe<UpdateStoreSettingsData>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateStorePreferencesInput = {
  id: Scalars['Int'];
  languageCode?: InputMaybe<Scalars['String']>;
  paymentMethodCode?: InputMaybe<Scalars['String']>;
  timezoneIdentifier?: InputMaybe<Scalars['String']>;
};

export type UpdateStoreSettingsData = {
  id?: InputMaybe<Scalars['Int']>;
  supportContactEmail?: InputMaybe<Scalars['String']>;
  supportContactPhone?: InputMaybe<Scalars['String']>;
};

export type UpdateStoreSettingsInput = {
  cutoffData?: InputMaybe<CutoffData>;
  dropSizeData?: InputMaybe<MoneyData>;
  id: Scalars['Int'];
  languagesCodes?: InputMaybe<Array<Scalars['String']>>;
  paymentMethodsCodes?: InputMaybe<Array<Scalars['String']>>;
  supportContactEmail?: InputMaybe<Scalars['String']>;
  supportContactPhone?: InputMaybe<Scalars['String']>;
  timezoneIdentifiers?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateSupplierInput = {
  avatar?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SupplierType>;
};

export type UpdateSupplierSettingsInput = {
  currency: Scalars['String'];
  cutoffData?: InputMaybe<CutoffData>;
  dropSizeData?: InputMaybe<MoneyData>;
  id: Scalars['Int'];
  languagesCodes: Array<Scalars['String']>;
  paymentMethodsCodes: Array<Scalars['String']>;
  preferenceData: PreferenceData;
  supplierId?: InputMaybe<Scalars['Int']>;
  supportContactEmail?: InputMaybe<Scalars['String']>;
  supportContactPhone?: InputMaybe<Scalars['String']>;
  timezoneIdentifiers: Array<Scalars['String']>;
};

export type UpdateTimezoneInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  identifier: Scalars['String'];
  utcOffset?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserStoreInput = {
  name: Scalars['String'];
  oldUsername: Scalars['String'];
  storeId: Scalars['Int'];
};

export type UpsertStoreSettingsInput = {
  dropSizeAmount?: InputMaybe<Scalars['Float']>;
  internalCode: Scalars['String'];
  supportContactEmail?: InputMaybe<Scalars['String']>;
  supportContactPhone?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  countryCode?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  loginMethod: LoginMethodType;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  status: UserStatusType;
  sub: Scalars['String'];
  supplierId: Scalars['Int'];
  userType: UserType;
  username: Scalars['String'];
};

export type UserAuthenticatedResponse = {
  __typename?: 'UserAuthenticatedResponse';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type UserCheckedResponse = {
  __typename?: 'UserCheckedResponse';
  nextStep: AuthNextStep;
};

export type UserCreated = {
  __typename?: 'UserCreated';
  deliveryDetails?: Maybe<DeliveryDetails>;
  verified: Scalars['Boolean'];
};

export enum UserStatusType {
  ChallengeRequired = 'CHALLENGE_REQUIRED',
  Confirmed = 'CONFIRMED'
}

export enum UserType {
  Admin = 'ADMIN',
  Distributor = 'DISTRIBUTOR',
  Manufacturer = 'MANUFACTURER',
  Store = 'STORE',
  Supplier = 'SUPPLIER'
}

export enum UserTypeSupplier {
  SalesRep = 'SALES_REP',
  Supplier = 'SUPPLIER'
}

export type UserVerified = {
  __typename?: 'UserVerified';
  success?: Maybe<Scalars['Boolean']>;
};

export type VerificationCodeSentResponse = {
  __typename?: 'VerificationCodeSentResponse';
  deliveryDetails?: Maybe<DeliveryDetails>;
};
