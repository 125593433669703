import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindPromotionsQueryVariables = Types.Exact<{
  input: Types.FindPromotionsInput;
}>;


export type FindPromotionsQuery = { __typename?: 'Query', findPromotions: { __typename?: 'PagedPromotion', promotions: Array<{ __typename?: 'Promotion', id: number, name: string, internalCode: string, supplierId: number, enabled: boolean, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindStoresInPromotionQueryVariables = Types.Exact<{
  input: Types.FindStoresInPromotionInput;
}>;


export type FindStoresInPromotionQuery = { __typename?: 'Query', findStoresInPromotion: { __typename?: 'PagedStore', stores: Array<{ __typename?: 'Store', id: number, legalName: string, internalCode?: string | null } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindOnePromotionQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type FindOnePromotionQuery = { __typename?: 'Query', findPromotionById?: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType, discountTiers?: Array<{ __typename?: 'DiscountTier', id: number, min: number, max?: number | null, discountPercentage: number }> | null, bonusTier?: { __typename?: 'BonusTier', id: number, quantityGiven: number, quantityRequired: number, rewardedDisplayProductId: number, rewardedDisplayProduct: { __typename?: 'DisplayProduct', id?: number | null, enabled: boolean, units: number, internalCode: string, productId?: number | null, default: boolean, codeDUN?: string | null, codeUPC?: string | null, product?: { __typename?: 'Product', identificationCode: string } | null } } | null, stores?: Array<{ __typename?: 'Store', id: number, internalCode?: string | null }> | null, products?: Array<{ __typename?: 'BasicProductInPromotion', id: number, name: string, image: string, enabled: boolean, identificationCode: string, maxDisplayUnits: number, boxRatio: number, description: string, codeDUN?: string | null, codeUPC?: string | null }> | null } | null };

export type ChangePromotionStatusMutationVariables = Types.Exact<{
  input: Types.SetPromotionStatusInput;
}>;


export type ChangePromotionStatusMutation = { __typename?: 'Mutation', setPromotionStatus: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, internalCode: string, supplierId: number, enabled: boolean, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type CreateSimpleDiscountPromotionMutationVariables = Types.Exact<{
  input: Types.CreateSimpleDiscountPromotionInput;
}>;


export type CreateSimpleDiscountPromotionMutation = { __typename?: 'Mutation', createSimpleDiscountPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type CreateMultipleDiscountPromotionMutationVariables = Types.Exact<{
  input: Types.CreateMultipleDiscountPromotionInput;
}>;


export type CreateMultipleDiscountPromotionMutation = { __typename?: 'Mutation', createMultipleDiscountPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type CreateIndividualBonusPromotionMutationVariables = Types.Exact<{
  input: Types.CreateIndividualBonusPromotionInput;
}>;


export type CreateIndividualBonusPromotionMutation = { __typename?: 'Mutation', createIndividualBonusPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type CreateCrossedBonusPromotionMutationVariables = Types.Exact<{
  input: Types.CreateCrossedBonusPromotionInput;
}>;


export type CreateCrossedBonusPromotionMutation = { __typename?: 'Mutation', createCrossedBonusPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type UpdateSimpleDiscountPromotionMutationVariables = Types.Exact<{
  input: Types.UpdateSimpleDiscountPromotionInput;
}>;


export type UpdateSimpleDiscountPromotionMutation = { __typename?: 'Mutation', updateSimpleDiscountPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type UpdateMultipleDiscountPromotionMutationVariables = Types.Exact<{
  input: Types.UpdateMultipleDiscountPromotionInput;
}>;


export type UpdateMultipleDiscountPromotionMutation = { __typename?: 'Mutation', updateMultipleDiscountPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type UpdateIndividualBonusPromotionMutationVariables = Types.Exact<{
  input: Types.UpdateIndividualBonusPromotionInput;
}>;


export type UpdateIndividualBonusPromotionMutation = { __typename?: 'Mutation', updateIndividualBonusPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type UpdateCrossedBonusPromotionMutationVariables = Types.Exact<{
  input: Types.UpdateCrossedBonusPromotionInput;
}>;


export type UpdateCrossedBonusPromotionMutation = { __typename?: 'Mutation', updateCrossedBonusPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, enabled: boolean, internalCode: string, supplierId: number, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type AddStoreToPromotionMutationVariables = Types.Exact<{
  input: Types.AddStoreToPromotionInput;
}>;


export type AddStoreToPromotionMutation = { __typename?: 'Mutation', addStoreToPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, internalCode: string, supplierId: number, enabled: boolean, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type RemoveStoreFromPromotionMutationVariables = Types.Exact<{
  input: Types.RemoveStoreFromPromotionInput;
}>;


export type RemoveStoreFromPromotionMutation = { __typename?: 'Mutation', removeStoreFromPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, internalCode: string, supplierId: number, enabled: boolean, startDate: string, endDate: string, image?: string | null, title?: string | null, description?: string | null, type: Types.PromotionType } } };

export type GetUrlForPromotionImageUploadQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type GetUrlForPromotionImageUploadQuery = { __typename?: 'Query', getUrlForPromotionImageUpload?: { __typename?: 'GetUrlForPromotionImageUploadResponse', url: string, key: string } | null };


export const FindPromotionsDocument = gql`
    query FindPromotions($input: FindPromotionsInput!) {
  findPromotions(findPromotionsInput: $input) {
    promotions {
      id
      name
      internalCode
      supplierId
      enabled
      startDate
      endDate
      image
      title
      description
      type
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindPromotionsQuery__
 *
 * To run a query within a React component, call `useFindPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPromotionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindPromotionsQuery(baseOptions: Apollo.QueryHookOptions<FindPromotionsQuery, FindPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPromotionsQuery, FindPromotionsQueryVariables>(FindPromotionsDocument, options);
      }
export function useFindPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPromotionsQuery, FindPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPromotionsQuery, FindPromotionsQueryVariables>(FindPromotionsDocument, options);
        }
export type FindPromotionsQueryHookResult = ReturnType<typeof useFindPromotionsQuery>;
export type FindPromotionsLazyQueryHookResult = ReturnType<typeof useFindPromotionsLazyQuery>;
export type FindPromotionsQueryResult = Apollo.QueryResult<FindPromotionsQuery, FindPromotionsQueryVariables>;
export const FindStoresInPromotionDocument = gql`
    query FindStoresInPromotion($input: FindStoresInPromotionInput!) {
  findStoresInPromotion(findStoresInPromotionInput: $input) {
    stores {
      id
      legalName
      internalCode
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindStoresInPromotionQuery__
 *
 * To run a query within a React component, call `useFindStoresInPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStoresInPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStoresInPromotionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindStoresInPromotionQuery(baseOptions: Apollo.QueryHookOptions<FindStoresInPromotionQuery, FindStoresInPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStoresInPromotionQuery, FindStoresInPromotionQueryVariables>(FindStoresInPromotionDocument, options);
      }
export function useFindStoresInPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStoresInPromotionQuery, FindStoresInPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStoresInPromotionQuery, FindStoresInPromotionQueryVariables>(FindStoresInPromotionDocument, options);
        }
export type FindStoresInPromotionQueryHookResult = ReturnType<typeof useFindStoresInPromotionQuery>;
export type FindStoresInPromotionLazyQueryHookResult = ReturnType<typeof useFindStoresInPromotionLazyQuery>;
export type FindStoresInPromotionQueryResult = Apollo.QueryResult<FindStoresInPromotionQuery, FindStoresInPromotionQueryVariables>;
export const FindOnePromotionDocument = gql`
    query FindOnePromotion($id: Int!) {
  findPromotionById(id: $id) {
    id
    name
    enabled
    internalCode
    supplierId
    startDate
    endDate
    image
    title
    description
    type
    discountTiers {
      id
      min
      max
      discountPercentage
    }
    bonusTier {
      id
      quantityGiven
      quantityRequired
      rewardedDisplayProductId
      rewardedDisplayProduct {
        id
        enabled
        units
        internalCode
        productId
        default
        codeDUN
        codeUPC
        product {
          identificationCode
        }
      }
    }
    stores {
      id
      internalCode
    }
    products {
      id
      name
      image
      enabled
      identificationCode
      maxDisplayUnits
      boxRatio
      description
      codeDUN
      codeUPC
    }
  }
}
    `;

/**
 * __useFindOnePromotionQuery__
 *
 * To run a query within a React component, call `useFindOnePromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOnePromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOnePromotionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOnePromotionQuery(baseOptions: Apollo.QueryHookOptions<FindOnePromotionQuery, FindOnePromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOnePromotionQuery, FindOnePromotionQueryVariables>(FindOnePromotionDocument, options);
      }
export function useFindOnePromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOnePromotionQuery, FindOnePromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOnePromotionQuery, FindOnePromotionQueryVariables>(FindOnePromotionDocument, options);
        }
export type FindOnePromotionQueryHookResult = ReturnType<typeof useFindOnePromotionQuery>;
export type FindOnePromotionLazyQueryHookResult = ReturnType<typeof useFindOnePromotionLazyQuery>;
export type FindOnePromotionQueryResult = Apollo.QueryResult<FindOnePromotionQuery, FindOnePromotionQueryVariables>;
export const ChangePromotionStatusDocument = gql`
    mutation ChangePromotionStatus($input: SetPromotionStatusInput!) {
  setPromotionStatus(setPromotionStatusInput: $input) {
    promotion {
      id
      name
      internalCode
      supplierId
      enabled
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type ChangePromotionStatusMutationFn = Apollo.MutationFunction<ChangePromotionStatusMutation, ChangePromotionStatusMutationVariables>;

/**
 * __useChangePromotionStatusMutation__
 *
 * To run a mutation, you first call `useChangePromotionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePromotionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePromotionStatusMutation, { data, loading, error }] = useChangePromotionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePromotionStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangePromotionStatusMutation, ChangePromotionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePromotionStatusMutation, ChangePromotionStatusMutationVariables>(ChangePromotionStatusDocument, options);
      }
export type ChangePromotionStatusMutationHookResult = ReturnType<typeof useChangePromotionStatusMutation>;
export type ChangePromotionStatusMutationResult = Apollo.MutationResult<ChangePromotionStatusMutation>;
export type ChangePromotionStatusMutationOptions = Apollo.BaseMutationOptions<ChangePromotionStatusMutation, ChangePromotionStatusMutationVariables>;
export const CreateSimpleDiscountPromotionDocument = gql`
    mutation CreateSimpleDiscountPromotion($input: CreateSimpleDiscountPromotionInput!) {
  createSimpleDiscountPromotion(createSimpleDiscountPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type CreateSimpleDiscountPromotionMutationFn = Apollo.MutationFunction<CreateSimpleDiscountPromotionMutation, CreateSimpleDiscountPromotionMutationVariables>;

/**
 * __useCreateSimpleDiscountPromotionMutation__
 *
 * To run a mutation, you first call `useCreateSimpleDiscountPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimpleDiscountPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimpleDiscountPromotionMutation, { data, loading, error }] = useCreateSimpleDiscountPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimpleDiscountPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSimpleDiscountPromotionMutation, CreateSimpleDiscountPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSimpleDiscountPromotionMutation, CreateSimpleDiscountPromotionMutationVariables>(CreateSimpleDiscountPromotionDocument, options);
      }
export type CreateSimpleDiscountPromotionMutationHookResult = ReturnType<typeof useCreateSimpleDiscountPromotionMutation>;
export type CreateSimpleDiscountPromotionMutationResult = Apollo.MutationResult<CreateSimpleDiscountPromotionMutation>;
export type CreateSimpleDiscountPromotionMutationOptions = Apollo.BaseMutationOptions<CreateSimpleDiscountPromotionMutation, CreateSimpleDiscountPromotionMutationVariables>;
export const CreateMultipleDiscountPromotionDocument = gql`
    mutation CreateMultipleDiscountPromotion($input: CreateMultipleDiscountPromotionInput!) {
  createMultipleDiscountPromotion(createMultipleDiscountPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type CreateMultipleDiscountPromotionMutationFn = Apollo.MutationFunction<CreateMultipleDiscountPromotionMutation, CreateMultipleDiscountPromotionMutationVariables>;

/**
 * __useCreateMultipleDiscountPromotionMutation__
 *
 * To run a mutation, you first call `useCreateMultipleDiscountPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleDiscountPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleDiscountPromotionMutation, { data, loading, error }] = useCreateMultipleDiscountPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultipleDiscountPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultipleDiscountPromotionMutation, CreateMultipleDiscountPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMultipleDiscountPromotionMutation, CreateMultipleDiscountPromotionMutationVariables>(CreateMultipleDiscountPromotionDocument, options);
      }
export type CreateMultipleDiscountPromotionMutationHookResult = ReturnType<typeof useCreateMultipleDiscountPromotionMutation>;
export type CreateMultipleDiscountPromotionMutationResult = Apollo.MutationResult<CreateMultipleDiscountPromotionMutation>;
export type CreateMultipleDiscountPromotionMutationOptions = Apollo.BaseMutationOptions<CreateMultipleDiscountPromotionMutation, CreateMultipleDiscountPromotionMutationVariables>;
export const CreateIndividualBonusPromotionDocument = gql`
    mutation CreateIndividualBonusPromotion($input: CreateIndividualBonusPromotionInput!) {
  createIndividualBonusPromotion(createIndividualBonusPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type CreateIndividualBonusPromotionMutationFn = Apollo.MutationFunction<CreateIndividualBonusPromotionMutation, CreateIndividualBonusPromotionMutationVariables>;

/**
 * __useCreateIndividualBonusPromotionMutation__
 *
 * To run a mutation, you first call `useCreateIndividualBonusPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndividualBonusPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndividualBonusPromotionMutation, { data, loading, error }] = useCreateIndividualBonusPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndividualBonusPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreateIndividualBonusPromotionMutation, CreateIndividualBonusPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIndividualBonusPromotionMutation, CreateIndividualBonusPromotionMutationVariables>(CreateIndividualBonusPromotionDocument, options);
      }
export type CreateIndividualBonusPromotionMutationHookResult = ReturnType<typeof useCreateIndividualBonusPromotionMutation>;
export type CreateIndividualBonusPromotionMutationResult = Apollo.MutationResult<CreateIndividualBonusPromotionMutation>;
export type CreateIndividualBonusPromotionMutationOptions = Apollo.BaseMutationOptions<CreateIndividualBonusPromotionMutation, CreateIndividualBonusPromotionMutationVariables>;
export const CreateCrossedBonusPromotionDocument = gql`
    mutation CreateCrossedBonusPromotion($input: CreateCrossedBonusPromotionInput!) {
  createCrossedBonusPromotion(createCrossedBonusPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type CreateCrossedBonusPromotionMutationFn = Apollo.MutationFunction<CreateCrossedBonusPromotionMutation, CreateCrossedBonusPromotionMutationVariables>;

/**
 * __useCreateCrossedBonusPromotionMutation__
 *
 * To run a mutation, you first call `useCreateCrossedBonusPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCrossedBonusPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCrossedBonusPromotionMutation, { data, loading, error }] = useCreateCrossedBonusPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCrossedBonusPromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCrossedBonusPromotionMutation, CreateCrossedBonusPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCrossedBonusPromotionMutation, CreateCrossedBonusPromotionMutationVariables>(CreateCrossedBonusPromotionDocument, options);
      }
export type CreateCrossedBonusPromotionMutationHookResult = ReturnType<typeof useCreateCrossedBonusPromotionMutation>;
export type CreateCrossedBonusPromotionMutationResult = Apollo.MutationResult<CreateCrossedBonusPromotionMutation>;
export type CreateCrossedBonusPromotionMutationOptions = Apollo.BaseMutationOptions<CreateCrossedBonusPromotionMutation, CreateCrossedBonusPromotionMutationVariables>;
export const UpdateSimpleDiscountPromotionDocument = gql`
    mutation UpdateSimpleDiscountPromotion($input: UpdateSimpleDiscountPromotionInput!) {
  updateSimpleDiscountPromotion(updateSimpleDiscountPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type UpdateSimpleDiscountPromotionMutationFn = Apollo.MutationFunction<UpdateSimpleDiscountPromotionMutation, UpdateSimpleDiscountPromotionMutationVariables>;

/**
 * __useUpdateSimpleDiscountPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateSimpleDiscountPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimpleDiscountPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimpleDiscountPromotionMutation, { data, loading, error }] = useUpdateSimpleDiscountPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimpleDiscountPromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimpleDiscountPromotionMutation, UpdateSimpleDiscountPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSimpleDiscountPromotionMutation, UpdateSimpleDiscountPromotionMutationVariables>(UpdateSimpleDiscountPromotionDocument, options);
      }
export type UpdateSimpleDiscountPromotionMutationHookResult = ReturnType<typeof useUpdateSimpleDiscountPromotionMutation>;
export type UpdateSimpleDiscountPromotionMutationResult = Apollo.MutationResult<UpdateSimpleDiscountPromotionMutation>;
export type UpdateSimpleDiscountPromotionMutationOptions = Apollo.BaseMutationOptions<UpdateSimpleDiscountPromotionMutation, UpdateSimpleDiscountPromotionMutationVariables>;
export const UpdateMultipleDiscountPromotionDocument = gql`
    mutation UpdateMultipleDiscountPromotion($input: UpdateMultipleDiscountPromotionInput!) {
  updateMultipleDiscountPromotion(updateMultipleDiscountPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type UpdateMultipleDiscountPromotionMutationFn = Apollo.MutationFunction<UpdateMultipleDiscountPromotionMutation, UpdateMultipleDiscountPromotionMutationVariables>;

/**
 * __useUpdateMultipleDiscountPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleDiscountPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleDiscountPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleDiscountPromotionMutation, { data, loading, error }] = useUpdateMultipleDiscountPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMultipleDiscountPromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMultipleDiscountPromotionMutation, UpdateMultipleDiscountPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMultipleDiscountPromotionMutation, UpdateMultipleDiscountPromotionMutationVariables>(UpdateMultipleDiscountPromotionDocument, options);
      }
export type UpdateMultipleDiscountPromotionMutationHookResult = ReturnType<typeof useUpdateMultipleDiscountPromotionMutation>;
export type UpdateMultipleDiscountPromotionMutationResult = Apollo.MutationResult<UpdateMultipleDiscountPromotionMutation>;
export type UpdateMultipleDiscountPromotionMutationOptions = Apollo.BaseMutationOptions<UpdateMultipleDiscountPromotionMutation, UpdateMultipleDiscountPromotionMutationVariables>;
export const UpdateIndividualBonusPromotionDocument = gql`
    mutation UpdateIndividualBonusPromotion($input: UpdateIndividualBonusPromotionInput!) {
  updateIndividualBonusPromotion(updateIndividualBonusPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type UpdateIndividualBonusPromotionMutationFn = Apollo.MutationFunction<UpdateIndividualBonusPromotionMutation, UpdateIndividualBonusPromotionMutationVariables>;

/**
 * __useUpdateIndividualBonusPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateIndividualBonusPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndividualBonusPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndividualBonusPromotionMutation, { data, loading, error }] = useUpdateIndividualBonusPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIndividualBonusPromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndividualBonusPromotionMutation, UpdateIndividualBonusPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIndividualBonusPromotionMutation, UpdateIndividualBonusPromotionMutationVariables>(UpdateIndividualBonusPromotionDocument, options);
      }
export type UpdateIndividualBonusPromotionMutationHookResult = ReturnType<typeof useUpdateIndividualBonusPromotionMutation>;
export type UpdateIndividualBonusPromotionMutationResult = Apollo.MutationResult<UpdateIndividualBonusPromotionMutation>;
export type UpdateIndividualBonusPromotionMutationOptions = Apollo.BaseMutationOptions<UpdateIndividualBonusPromotionMutation, UpdateIndividualBonusPromotionMutationVariables>;
export const UpdateCrossedBonusPromotionDocument = gql`
    mutation UpdateCrossedBonusPromotion($input: UpdateCrossedBonusPromotionInput!) {
  updateCrossedBonusPromotion(updateCrossedBonusPromotionInput: $input) {
    promotion {
      id
      name
      enabled
      internalCode
      supplierId
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type UpdateCrossedBonusPromotionMutationFn = Apollo.MutationFunction<UpdateCrossedBonusPromotionMutation, UpdateCrossedBonusPromotionMutationVariables>;

/**
 * __useUpdateCrossedBonusPromotionMutation__
 *
 * To run a mutation, you first call `useUpdateCrossedBonusPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrossedBonusPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrossedBonusPromotionMutation, { data, loading, error }] = useUpdateCrossedBonusPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCrossedBonusPromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrossedBonusPromotionMutation, UpdateCrossedBonusPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrossedBonusPromotionMutation, UpdateCrossedBonusPromotionMutationVariables>(UpdateCrossedBonusPromotionDocument, options);
      }
export type UpdateCrossedBonusPromotionMutationHookResult = ReturnType<typeof useUpdateCrossedBonusPromotionMutation>;
export type UpdateCrossedBonusPromotionMutationResult = Apollo.MutationResult<UpdateCrossedBonusPromotionMutation>;
export type UpdateCrossedBonusPromotionMutationOptions = Apollo.BaseMutationOptions<UpdateCrossedBonusPromotionMutation, UpdateCrossedBonusPromotionMutationVariables>;
export const AddStoreToPromotionDocument = gql`
    mutation AddStoreToPromotion($input: AddStoreToPromotionInput!) {
  addStoreToPromotion(addStoreToPromotionInput: $input) {
    promotion {
      id
      name
      internalCode
      supplierId
      enabled
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type AddStoreToPromotionMutationFn = Apollo.MutationFunction<AddStoreToPromotionMutation, AddStoreToPromotionMutationVariables>;

/**
 * __useAddStoreToPromotionMutation__
 *
 * To run a mutation, you first call `useAddStoreToPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreToPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreToPromotionMutation, { data, loading, error }] = useAddStoreToPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoreToPromotionMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreToPromotionMutation, AddStoreToPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreToPromotionMutation, AddStoreToPromotionMutationVariables>(AddStoreToPromotionDocument, options);
      }
export type AddStoreToPromotionMutationHookResult = ReturnType<typeof useAddStoreToPromotionMutation>;
export type AddStoreToPromotionMutationResult = Apollo.MutationResult<AddStoreToPromotionMutation>;
export type AddStoreToPromotionMutationOptions = Apollo.BaseMutationOptions<AddStoreToPromotionMutation, AddStoreToPromotionMutationVariables>;
export const RemoveStoreFromPromotionDocument = gql`
    mutation RemoveStoreFromPromotion($input: RemoveStoreFromPromotionInput!) {
  removeStoreFromPromotion(removeStoreFromPromotionInput: $input) {
    promotion {
      id
      name
      internalCode
      supplierId
      enabled
      startDate
      endDate
      image
      title
      description
      type
    }
  }
}
    `;
export type RemoveStoreFromPromotionMutationFn = Apollo.MutationFunction<RemoveStoreFromPromotionMutation, RemoveStoreFromPromotionMutationVariables>;

/**
 * __useRemoveStoreFromPromotionMutation__
 *
 * To run a mutation, you first call `useRemoveStoreFromPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreFromPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreFromPromotionMutation, { data, loading, error }] = useRemoveStoreFromPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStoreFromPromotionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreFromPromotionMutation, RemoveStoreFromPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreFromPromotionMutation, RemoveStoreFromPromotionMutationVariables>(RemoveStoreFromPromotionDocument, options);
      }
export type RemoveStoreFromPromotionMutationHookResult = ReturnType<typeof useRemoveStoreFromPromotionMutation>;
export type RemoveStoreFromPromotionMutationResult = Apollo.MutationResult<RemoveStoreFromPromotionMutation>;
export type RemoveStoreFromPromotionMutationOptions = Apollo.BaseMutationOptions<RemoveStoreFromPromotionMutation, RemoveStoreFromPromotionMutationVariables>;
export const GetUrlForPromotionImageUploadDocument = gql`
    query GetUrlForPromotionImageUpload($name: String!) {
  getUrlForPromotionImageUpload(name: $name) {
    url
    key
  }
}
    `;

/**
 * __useGetUrlForPromotionImageUploadQuery__
 *
 * To run a query within a React component, call `useGetUrlForPromotionImageUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlForPromotionImageUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlForPromotionImageUploadQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetUrlForPromotionImageUploadQuery(baseOptions: Apollo.QueryHookOptions<GetUrlForPromotionImageUploadQuery, GetUrlForPromotionImageUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlForPromotionImageUploadQuery, GetUrlForPromotionImageUploadQueryVariables>(GetUrlForPromotionImageUploadDocument, options);
      }
export function useGetUrlForPromotionImageUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlForPromotionImageUploadQuery, GetUrlForPromotionImageUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlForPromotionImageUploadQuery, GetUrlForPromotionImageUploadQueryVariables>(GetUrlForPromotionImageUploadDocument, options);
        }
export type GetUrlForPromotionImageUploadQueryHookResult = ReturnType<typeof useGetUrlForPromotionImageUploadQuery>;
export type GetUrlForPromotionImageUploadLazyQueryHookResult = ReturnType<typeof useGetUrlForPromotionImageUploadLazyQuery>;
export type GetUrlForPromotionImageUploadQueryResult = Apollo.QueryResult<GetUrlForPromotionImageUploadQuery, GetUrlForPromotionImageUploadQueryVariables>;